/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  get, find, findIndex, includes, size, isEmpty, assign, filter, orderBy,
} from 'lodash';
import { Redirect } from 'react-router-dom';
import { ArrowBackIos } from '@material-ui/icons';
import { AppBar, Toolbar } from '@material-ui/core';
import Persona from 'persona';
import HelmetTag from '../Shared/HelmetTag';
import {
  setBookingFlowStep, setCart, setProducts, loadClient, setBookingFlowPreviousStep, setField,
  setEvent
} from '../../Actions';
import { HOST_ROOT } from '../../apiConfig';
import MainBackgroundWrapper from '../Shared/MainBackgroundWrapperForMarketplace';
import { seBookingStart, componentMountedInitCalls, hashAffiliateUtms } from '../Shared/WebAnalytics';
import TimingStep from './TimingStep';
import { STEPS, PROGRESS_PERC, PROGRESS_STEPS } from './Shared/constants';
import tempImg from '../../Assets/Images/temp-bg.png';
import ProviderPickStep from './ProviderPickStep';
import AuthenticationStep from './AuthenticationStep';
import ReviewStep from './ReviewStep';
import {
  getCookie, setCookie, ROUTES, hasUuid, blankHref,
  isEventBooking,
  defaultErrorMessage,
} from '../../constants';
import ErrorModal from '../Shared/ErrorModal';
import CheckoutStep from './CheckoutStep';
import ConfirmationStep from './ConfirmationStep';
import LoadingOverlay from '../Shared/LoadingOverlay';
import { getGuestCart, getProducts, initGuest } from './Shared/helpers';
import { decode } from './Shared/encode';
import './Assets/Styles/index.css';
import TreatmentsMenu from './TreatmentsMenu';
import NavbarRedesignForMarketplace from '../Shared/NavbarRedesignForMarketplace';
import LinearBuffer from '../Shared/LinearBuffer';
import { sendAttributions, verifyPhoneNumber } from '../Shared/Helpers';
import DashedPizzaTracker from '../Shared/DashedPizzaTracker';
import NavbarRedesign from '../Shared/NavbarRedesign';
import ProviderPickStepMarketplace from './ProviderPickStepMarketplace';
import ProviderPickStep2ndIteration from './ProviderPickStep2ndIteration';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bgImagePath: tempImg,
      darkenHeader: false,
      error: null,
      showErrorModal: false,
      currentStepId: '',
      currentStepState: null,
      showBgFlag: false,
      showLoader: false,
      redirect: false,
      width: window.innerWidth,
      exceptionUiShowBtm: false,
    };
    this.changeBackground = this.changeBackground.bind(this);
    this.toggleNavBarStyle = this.toggleNavBarStyle.bind(this);
    this.renderStep = this.renderStep.bind(this);
    this.assignToCart = this.assignToCart.bind(this);
    this.resetCart = this.resetCart.bind(this);
    this.triggerBookingStartEvent = this.triggerBookingStartEvent.bind(this);
    this.applyB2bDiscount = this.applyB2bDiscount.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.relevantGoBack = this.relevantGoBack.bind(this);
    this.isDarkHeader = this.isDarkHeader.bind(this);
    this.showVideoBg = this.showVideoBg.bind(this);
    this.hideHeaderActions = this.hideHeaderActions.bind(this);
    this.updateBookingStep = this.updateBookingStep.bind(this);
    this.goToAuthentication = this.goToAuthentication.bind(this);
    this.displayError = this.displayError.bind(this);
    this.setLoaderFlag = this.setLoaderFlag.bind(this);
    this.getB2bUuid = this.getB2bUuid.bind(this);
    this.getEventUuid = this.getEventUuid.bind(this);
    this.createGuest = this.createGuest.bind(this);
    this.responsiveTopper = this.responsiveTopper.bind(this);
    this.initiateOrShowPersona = this.initiateOrShowPersona.bind(this);
    this.removePersonaInstance = this.removePersonaInstance.bind(this);
    this.fetchProducts = this.fetchProducts.bind(this);
  }

  componentDidMount() {
    const currentStep = get(this.props, 'bookingFlow.step', STEPS[0]);
    const cartId = get(this.props, 'booking.cart.id', null);
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const rebook = get(this.props, 'location.state.booking.cart.rebook', false);
    const self = this;
    const defaultStepId = get(this.props, 'match.params.step', '');
    const defaultStep = find(STEPS, (step) => (step.id === defaultStepId));
    if (defaultStep) {
      this.updateBookingStep(defaultStep, true);
    } else {
      this.updateBookingStep(STEPS[0], true);
    }
    // cart reset
    if ((!hasUuid() && (!isEventBooking() || (isEventBooking() && currentStep === 'CONFIRMATION')) && (!loggedIn || (!cartId && !rebook) || (currentStep === 'CONFIRMATION')))) {
      this.resetCart();
    } else if (isEventBooking() && !cartId) {
      this.updateBookingStep(STEPS[0]);
    }
    if (rebook && cartId) {
      const defaultStepId = get(this.props, 'match.params.step', '');
      const defaultStep = find(STEPS, (step) => (step.id === defaultStepId));
      this.assignToCart(get(this.props, 'location.state.booking', {}));
      this.assignToCart(get(this.props, 'location.state.products.0', null));
      this.props.setProducts({ products: get(this.props, 'location.state.products', []) });
      this.updateBookingStep(defaultStep);
    }
    if (hasUuid()) {
      const defaultStep = find(STEPS, (step) => (step.id === 'TIMING'));
      this.updateBookingStep(defaultStep);
    }
    if (get(window, 'performance.navigation.type', 0) === 0) {
      const utmObj = hashAffiliateUtms();
      if (!isEmpty(utmObj)) {
        sendAttributions(assign(utmObj, { location: 'booking_flow_start' }), get(this.props, 'client.user_id', null));
      }
    }
    // web analytics
    this.triggerBookingStartEvent(cartId);
    componentMountedInitCalls(this.props.client);
    this.getB2bUuid();
    this.getEventUuid();
    
    //  b2b discount
    this.applyB2bDiscount();

    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    window.addEventListener('scroll', (e) => {
      if (!self.state.scrolledDown && window.scrollY > 0) {
        self.setState({ scrolledDown: true });
      } else if (window.scrollY === 0 && self.state.scrolledDown) {
        self.setState({ scrolledDown: false });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const oldSlug = get(prevProps, 'match.params.step', '');
    const newSlug = get(this.props, 'match.params.step', '');
    if (oldSlug !== newSlug) {
      const defaultStepId = get(this.props, 'match.params.step', '');
      const defaultStep = find(STEPS, (step) => (step.id === defaultStepId));
      this.updateBookingStep(defaultStep, true);
    }
  }

  removePersonaInstance() {
    if (this.state.personaInstance) {
      this.setState({ personaInstance: null });
    }
  }

  initiateOrShowPersona(startEvent, completeEvent, skipEvent, cb, onlyToInitialize = false) {
    if (!this.state.personaInstance) {
      this.setState({
        personaInstance: new Persona.Client({
          fields: {
            phoneNumber: get(this.props, 'client.phone', ''),
            addressCountryCode: get(this.props, 'client.country', ''),
          },
          parent: document.getElementById('persona-custom-parent'),
          templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
          referenceId: get(this.props, 'client.user_id', ''),
          environment: process.env.REACT_APP_PERSONA_ENV_ID,
          // onReady: () => {
          //     startEvent()
          //     this.state.personaInstance.open();
          // },
          onComplete: ({ inquiryId, status, fields }) => {
            // Inquiry completed. Optionally tell your server about it.
            verifyPhoneNumber(get(this.props, 'client.user_id', ''), inquiryId, (resp) => {
              this.props.loadClient({ ...get(resp, 'data.client', {}), loggedIn: true });
              completeEvent();
              if (cb) {
                cb();
              }
            }, (err) => {
              this.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
              // this.setState({ personaInstance: null })
            });
          },
          onCancel: ({ inquiryId, sessionToken }) => {
            if (skipEvent) {
              skipEvent();
            }
            if (cb && get(this.props, 'client.loggedIn', false)) {
              cb();
            }
          },
          onError: (error) => {
            console.log(error);
            this.displayError(get(error, 'code', defaultErrorMessage));
          },
        }),
      });
    } else if (!onlyToInitialize) {
      startEvent();
      this.state.personaInstance.open();
    }
  }

  updateBookingStep(step, skipHistoryUpdate = false) {
    // step is coming empty TODO
    if (isEmpty(step)) {
      step = STEPS[0];
    }
    const searchValue = window.location.search;
    if (!skipHistoryUpdate && step.id !== 'AUTHENTICATION') {
      this.props.history.push(`/booking/${step.id}${searchValue}`);
    }
    this.setState({
      showLoader: false,
      currentStepId: step.id,
      currentStepState: step,
      showBgFlag: this.showVideoBg(step),
    }, () => {
      this.props.setBookingFlowStep(step);
    });
  }

  triggerBookingStartEvent(cartId) {
    const ind = findIndex(STEPS, (el) => (includes(window.location.href, el.id)));
    const tempAddrList = orderBy(get(this.props, 'addresses', []), ['default'], ['desc']);
    if (ind !== -1) {
      const serviceCategory = get(this.props, 'booking.product.title', ''); const firstTimeBooker = get(this.props, 'client.first_time_booker', false); const user_id = get(this.props, 'client.user_id', ''); const
        email = get(this.props, 'client.email', '');
      const loggedIn = get(this.props, 'client.loggedIn', false);
      const rebook = get(this.props, 'booking.cart.rebook', false);
      const market = get(this.props, 'booking.cart.address.market', get(tempAddrList, '0.market', ''));
      seBookingStart(firstTimeBooker, cartId, user_id, email, serviceCategory ? serviceCategory.toLowerCase() : '', loggedIn, rebook, 'booking_23_variant', market, "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
    }
  }

  createGuest(business, guest, event = false) {
    const selff = this;
    initGuest(business, guest, (resp) => {
      selff.setLoaderFlag(false);
      const cart_json = get(resp, 'data.cart_json', null);
      if (cart_json) {
        this.props.setField('csrfToken', get(resp, 'data.csrfToken', ''));
        this.props.setField('listingPageStorage', cart_json);
        this.toggleNavBarStyle(!get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
        this.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
        if (event) {
          this.fetchProducts();
        }
      }
    }, (error) => {
      selff.setLoaderFlag(false);
      console.log({ error });
    });
  }

  getB2bUuid() {
    const self = this;
    const parsedParams = queryString.parse(window.location.search);
    if (Object.keys(parsedParams).includes('guest') && Object.keys(parsedParams).includes('business')) {
      self.setLoaderFlag(true);
      getGuestCart(parsedParams.guest, (response_1) => {
        self.setLoaderFlag(false);
        const cart_1 = get(response_1, 'data.carts.cart', null);
        if (cart_1) {
          this.props.setField('csrfToken', get(response_1, 'data.csrfToken', ''));
          this.props.setField('listingPageStorage', get(response_1, 'data.carts', null));
          this.toggleNavBarStyle(!get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
          this.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
          this.assignToCart({ cart: decode(cart_1) });
        } else {
          this.createGuest(parsedParams.business, parsedParams.guest);
        }
      }, () => {
        this.createGuest(parsedParams.business, parsedParams.guest);
      });
    } else {
      this.props.setField('csrfToken', '');
      this.props.setField('listingPageStorage', null);
    }
  }
  getEventUuid() {
    const self = this;
    const parsedParams = queryString.parse(window.location.search);
    if (isEventBooking()) {
      self.setLoaderFlag(true);
      getGuestCart(parsedParams['event-organizer'], (response_1) => {
        self.setLoaderFlag(false);
        const cart_1 = get(response_1, 'data.carts.cart', null);
        if (cart_1) {
          this.props.setField('csrfToken', get(response_1, 'data.csrfToken', ''));
          this.props.setField('listingPageStorage', get(response_1, 'data.carts', null));
          this.toggleNavBarStyle(!get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
          this.changeBackground(get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', '') || 'none');
          this.assignToCart({ cart: decode(cart_1) });
          this.fetchProducts();
        } else {
          this.createGuest(null, parsedParams['event-organizer'], isEventBooking());
        }
      }, () => {
        this.createGuest(null, parsedParams['event-organizer'], isEventBooking());
      });
    } else {
      this.props.setField('csrfToken', '');
      this.props.setField('listingPageStorage', null);
    }
  }
  fetchProducts() {
    const address = get(this.props, 'fieldsHolder.listingPageStorage.address', {});
    const { zipCode } = address;
    const queryParam = `?zip_code=${zipCode}&event=true`;
    this.setLoaderFlag(true);
    getProducts(queryParam, (resp) => {
      const products = filter(get(resp, 'data.products', []), (el) => (el.bookable || isEmpty(address)));
      this.props.setProducts({ products });
      this.setLoaderFlag(false);
    }, (err) => {
      this.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
      this.setLoaderFlag(false);
    });
  }

  applyB2bDiscount() {
    const parsedParams = queryString.parse(window.location.search);
    let b2bDiscountCode = '';

    if (Object.keys(parsedParams).includes('aads')) {
      setCookie(parsedParams.aads);
      b2bDiscountCode = parsedParams.aads;
    } else if (getCookie('aads') !== null) {
      b2bDiscountCode = getCookie('aads');
    }

    this.assignToCart({ b2bDiscountCode });
  }

  resetCart() {
    this.props.setProducts({ products: [] });
    this.props.setCart({
      cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {},
    });
    this.updateBookingStep(STEPS[0]);
  }

  assignToCart(value) {
    this.props.setCart({
      ...this.props.booking,
      ...value,
    });
  }

  changeBackground(imgPath) {
    this.setState({ bgImagePath: imgPath });
  }

  toggleNavBarStyle(tgl = false) {
    const rebook = get(this.props, 'booking.cart.rebook', false);
    if (rebook && Number(this.state.width) <= 800) {
      tgl = true;
    }
    this.setState({ darkenHeader: tgl });
  }

  isDarkHeader() {
    return !get(this.props, 'bookingFlow.step.withBackground', STEPS[0].withBackground) || this.state.bgImagePath === 'none' || this.state.darkenHeader;
  }

  showVideoBg(step) {
    const withBg = get(step, 'withBackground', false);
    return Boolean(withBg);
  }

  hideHeaderActions() {
    return Boolean(get(this.props, 'bookingFlow.step.id', STEPS[0].id) === 'AUTHENTICATION');
  }

  setLoaderFlag(showLoader) {
    this.setState({ showLoader });
  }

  renderStep() {
    const parsedParams = queryString.parse(window.location.search);
    if (get(parsedParams, 'auth_error', null)) {
      return (
        <AuthenticationStep
          isSignin={this.state.isSignin}
          changeBackground={this.changeBackground}
          assignToCart={this.assignToCart}
          setBookingFlowStepThroughParent={this.updateBookingStep}
          displayError={this.displayError}
          setLoaderFlag={this.setLoaderFlag}
          jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
        />
      );
    }
    const currentStep = this.state.currentStepId;

    switch (currentStep) {
      case 'MENU':
        return (
          <TreatmentsMenu
            relevantGoBack={this.relevantGoBack}
            assignToCart={this.assignToCart}
            changeBackground={this.changeBackground}
            displayError={this.displayError}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            setLoaderFlag={this.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );
      case 'AUTHENTICATION':
        return (
          <AuthenticationStep
            initiateOrShowPersona={this.initiateOrShowPersona}
            removePersona={this.removePersonaInstance}
            isSignin={this.state.isSignin}
            changeBackground={this.changeBackground}
            assignToCart={this.assignToCart}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            displayError={this.displayError}
            setLoaderFlag={this.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );
      case 'TIMING':
        return (
          <TimingStep
            changeBackground={this.changeBackground}
            toggleNavBarStyle={this.toggleNavBarStyle}
            assignToCart={this.assignToCart}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            displayError={this.displayError}
            setLoaderFlag={this.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );
      case 'PICKAPRO':
      case 'PICKAPRO2':
        if (get(this.props, 'booking.cart.info_fields.marketplace_enabled', false)
          && size(get(this.props, 'booking.cart.cartProducts', [])) === 1) {
          if (get(this.props, 'jul_2024_booking_enhancements', 'show_old_flow') === 'show_enhancements') {
            return (
              <ProviderPickStep2ndIteration
                assignToCart={this.assignToCart}
                toggleNavBarStyle={this.toggleNavBarStyle}
                changeBackground={this.changeBackground}
                setBookingFlowStepThroughParent={this.updateBookingStep}
                displayError={this.displayError}
                setLoaderFlag={this.setLoaderFlag}
                jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
              />
            );
          }
          return (
            <ProviderPickStepMarketplace
              assignToCart={this.assignToCart}
              toggleNavBarStyle={this.toggleNavBarStyle}
              changeBackground={this.changeBackground}
              setBookingFlowStepThroughParent={this.updateBookingStep}
              displayError={this.displayError}
              setLoaderFlag={this.setLoaderFlag}
              jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
            />
          );
        }
        return (
          <ProviderPickStep
            assignToCart={this.assignToCart}
            toggleNavBarStyle={this.toggleNavBarStyle}
            changeBackground={this.changeBackground}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            displayError={this.displayError}
            setLoaderFlag={this.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );
      case 'REVIEW':
        return (
          <ReviewStep
            assignToCart={this.assignToCart}
            toggleNavBarStyle={this.toggleNavBarStyle}
            changeBackground={this.changeBackground}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            displayError={this.displayError}
            setLoaderFlag={this.setLoaderFlag}
            updateExceptionUi={(boolVal) => this.setState({ exceptionUiShowBtm: boolVal })}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );
      case 'CHECKOUT':
        return (
          <CheckoutStep
            initiateOrShowPersona={this.initiateOrShowPersona}
            removePersona={this.removePersonaInstance}
            assignToCart={this.assignToCart}
            toggleNavBarStyle={this.toggleNavBarStyle}
            changeBackground={this.changeBackground}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            displayError={this.displayError}
            setLoaderFlag={this.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );
      case 'CONFIRMATION':
        return (
          <ConfirmationStep
            assignToCart={this.assignToCart}
            changeBackground={this.changeBackground}
            toggleNavBarStyle={this.toggleNavBarStyle}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            displayError={this.displayError}
            setLoaderFlag={this.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );

      default:
        return (
          <TreatmentsMenu
            relevantGoBack={this.relevantGoBack}
            assignToCart={this.assignToCart}
            changeBackground={this.changeBackground}
            displayError={this.displayError}
            setBookingFlowStepThroughParent={this.updateBookingStep}
            setLoaderFlag={this.setLoaderFlag}
            jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
          />
        );
    }
  }

  displayError(error) {
    this.setState({ showLoader: false, showErrorModal: true, error });
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  goToAuthentication(isSignin) {
    this.setState({ isSignin }, () => {
      const nextStep = find(STEPS, (step) => (step.id === 'AUTHENTICATION'));
      this.updateBookingStep(nextStep);
    });
  }

  relevantGoBack() {
    const currentStep = get(this.props, 'bookingFlow.step.id', STEPS[0].id);
    const rebook = get(this.props, 'booking.cart.rebook', false);
    if (hasUuid() && (currentStep === 'CONFIRMATION' || currentStep === 'TIMING')) {
      const business = get(this.props, 'fieldsHolder.listingPageStorage.sub_domain', '');
      const location = get(this.props, 'fieldsHolder.listingPageStorage.uuid', '');
      const nextUrl = `https://${business}${process.env.REACT_APP_PERMITTED_DOMAIN}/${location}`;
      if (currentStep === 'CONFIRMATION') {
        this.props.setField('csrfToken', '');
        this.props.setField('listingPageStorage', null);
        this.props.setProducts({ products: [] });
        this.props.setCart({
          cart: {}, addressId: null, product: {}, cartProducts: [], currentCartProduct: {},
        });
      }
      window.location.href = nextUrl;
    } else if ((currentStep === 'MENU' && !rebook) || currentStep === 'CONFIRMATION') {
      window.location.href = `${HOST_ROOT}${ROUTES.index}`;
    } else if (currentStep === 'MENU' && rebook) {
      this.setState({ redirect: true });
    } else {
      this.updateBookingStep(get(this.props, 'bookingFlow.previousStep', STEPS[0]));
    }
  }

  responsiveTopper() {
    const showMenu = this.state.currentStepId === 'MENU';
    const isConfirmation = this.state.currentStepId === 'CONFIRMATION';
    const { currentStepId } = this.state;
    if (this.state.width < 800 && !showMenu && !isConfirmation && !(currentStepId === 'REVIEW' || currentStepId === 'CHECKOUT')) {
      return (
        <AppBar position="fixed" className={this.state.scrolledDown ? 'light-header' : 'light-header shadow-none-imp'}>
          <LinearBuffer stepNumber={get(find(PROGRESS_PERC, (el) => (el.id === this.state.currentStepId)), 'perc', 0)} />
          <Toolbar className="height-44 p-14-16 align-items-center justify-content-spaced">
            <div>
              <a
                href={blankHref}
                onClick={this.relevantGoBack}
                className="d-flex-only align-items-center contentPrimary size-18-24"
              >
                <ArrowBackIos className="size-18-28-imp" />
              </a>
            </div>
          </Toolbar>
        </AppBar>
      );
    }
    return null;
  }

  render() {
    const currentStep = this.state.currentStepId;
    const { bgImagePath, showLoader, currentStepId } = this.state;
    const stp = get(this.state, 'currentStepState', null);
    const isB2b = Boolean(hasUuid() && get(this.props, 'fieldsHolder.listingPageStorage.bannerimage', ''));
    const rebook = get(this.props, 'booking.cart.rebook', false);
    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: '/appointment_management/',
          state: {
            cart: get(this.props, 'booking.cart', {}),
            upcomingAppointmentId: get(this.props, 'location.state.upcomingAppointmentId', ''),
          },
        }}
        />
      );
    }
    return (
      <>
        {this.responsiveTopper()}
        <HelmetTag />
        {this.state.width < 800 && currentStepId !== 'MENU' && !this.state.exceptionUiShowBtm && !(currentStepId === 'REVIEW' || currentStepId === 'CHECKOUT') && !(currentStepId === 'CONFIRMATION' && isEventBooking()) ? null
          : this.state.width < 800 && (currentStepId === 'REVIEW' || currentStepId === 'CHECKOUT')
            ? (
              <NavbarRedesign
                relevantGoBack={this.relevantGoBack}
                goToAuthentication={this.goToAuthentication}
                darkenHeader={this.isDarkHeader()}
                hideHeaderActions={this.hideHeaderActions()}
                currentStepId={currentStepId}
                exceptionUiShowBtm={this.state.exceptionUiShowBtm}
                hideLogout
                jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
              />
            ) : (
              <NavbarRedesignForMarketplace
                relevantGoBack={this.relevantGoBack}
                goToAuthentication={this.goToAuthentication}
                darkenHeader={this.isDarkHeader()}
                hideHeaderActions={this.hideHeaderActions()}
                currentStepId={currentStepId}
                showMenu={currentStepId === 'MENU' && !rebook}
                jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
              />
            )}
        <MainBackgroundWrapper noMarginTop={!hasUuid() && this.state.width < 800 && currentStepId === 'ADDRESS'} bgImagePath={bgImagePath} showVideoBg={this.state.showBgFlag}>
          {currentStepId === 'CONFIRMATION' || currentStepId === 'MENU'
            || this.state.width < 800 && !(currentStepId === 'REVIEW' || currentStepId === 'CHECKOUT') ? null
            : currentStepId === 'REVIEW' || currentStepId === 'CHECKOUT' ? <DashedPizzaTracker progressSteps={hasUuid() ? filter(PROGRESS_STEPS, (el) => (el.id !== 'DATE')) : PROGRESS_STEPS} currentStep={get(this.state, 'currentStepState', null)} />
              : <LinearBuffer stepNumber={get(find(PROGRESS_PERC, (el) => (el.id === currentStepId)), 'perc', 0)} />}
          <div className={`${currentStepId === 'MENU' || currentStepId === 'CONFIRMATION' ? '' : 'mb-100'} ${get(stp, 'hasOpaqueBg', false) && !isB2b && !rebook && !isEventBooking() ? 'opaque-bg-wrapper' : isB2b || isEventBooking() ? ' dark-bg-wrapper' : ''}`}>
            {this.renderStep()}
          </div>
        </MainBackgroundWrapper>
        {this.errorModal()}
        <LoadingOverlay showLoader={showLoader} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  products: get(state, 'productsReducer.products', []),
  bookingFlow: state.bookingFlow,
  client: state.client,
  anonymousCart: state.anonymousCart,
  fieldsHolder: state.fieldsHolder,
  addresses: state.addresses,
});

export default connect(mapStateToProps, {
  setBookingFlowStep, setBookingFlowPreviousStep, setCart, setProducts, loadClient, setField, setEvent,
})(Index);
