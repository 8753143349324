/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  compact, get, isEmpty, set, sortBy, map, join, keys, filter,
} from 'lodash';
import { setBookingFlowStep, setCart, setBookingFlowPreviousStep } from '../../../Actions';
import ErrorModal from '../../Shared/ErrorModal';
import MapContainer from '../../Shared/MapContainer';
import RadioOptions from '../../Shared/RadioOptions';
import '../Assets/Styles/index.css';
import './Assets/Styles/FinishAddress.css';
import CheckboxOptions from '../../Shared/CheckboxOptions';
import { getAddressDetails, updateAddress, updateCart } from '../Shared/helpers';
import { HotJar, defaultErrorMessage } from '../../../constants';
import { decode } from '../Shared/encode';
import { seBookingAddressDetails } from '../../Shared/WebAnalytics';
import { ADDRESS_REQUIRED_FIELDS } from '../Shared/constants';
import LatestModalDesign from '../../Shared/LatestModalDesign';

HotJar();

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.setCart = this.setCart.bind(this);
    this.showMap = this.showMap.bind(this);
    this.showUpperSection = this.showUpperSection.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.showDetailsSection = this.showDetailsSection.bind(this);
    this.showByRelevantType = this.showByRelevantType.bind(this);
    this.displayInRadio = this.displayInRadio.bind(this);
    this.displayInCheckbox = this.displayInCheckbox.bind(this);
    this.displayInTextArea = this.displayInTextArea.bind(this);
    this.listViews = this.listViews.bind(this);
    this.submitDetailsAndNext = this.submitDetailsAndNext.bind(this);
    this.updateRequiredStatus = this.updateRequiredStatus.bind(this);
    this.addressUpdateCallBack = this.addressUpdateCallBack.bind(this);

    this.state = {
      width: window.innerWidth || 0,
      showErrorModal: false,
      message: '',
      newAddress: {},
      ctaDisabled: true,
    };
  }

  componentDidMount() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    window.scrollTo(0, 0);
    window.addEventListener('resize', () => this.setState({ width: window.innerWidth }));
    const addressId = get(this.props, 'booking.cart.address.id', '');
    if (addressId && loggedIn) {
      getAddressDetails(addressId, (response) => {
        const { display_helpers, settable_fields, info_fields } = get(response, 'data.address', {});
        const display_headers = get(display_helpers, 'v0.display_headers', {});
        const display_array = get(display_helpers, 'v0.display_array', []);
        this.setState({
          info_fields, settable_fields, display_headers, display_array,
        }, () => {
          this.updateRequiredStatus();
        });
      }, (err) => {
        this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
      });
    } else {
      const { display_headers, display_array, settable_fields } = ADDRESS_REQUIRED_FIELDS;
      this.setState({
        newAddress: { ...get(this.props, 'booking.cart.address', {}), parking_info: get(this.props, 'booking.cart.address.parking_info', null) }, settable_fields, display_headers, display_array,
      }, () => {
        this.updateRequiredStatus();
      });
      // TODO working with hardcoded object in the meantime
      // recheck the inputs default values
      // to add them to new address object on load
      // UPDATE: this was moved to the end of the booking flow instead of the start
      // so this is not an issue anymore since the user will be
    //    loggedin by the time they get to this step
    }
  }

  setCart(value) {
    this.props.setCart({
      ...this.props.booking,
      ...value,
    });
  }

  updateRequiredStatus() {
    const { settable_fields, newAddress } = this.state;
    let missingRequiredFields = [];
    if (!isEmpty(settable_fields)) {
      const settable_fields_keys = keys(settable_fields);
      missingRequiredFields = filter(settable_fields_keys, (val) => (settable_fields[val].required && isEmpty(settable_fields[val].value) && isEmpty(get(newAddress, val, null)) && val !== 'city' && val !== 'city_id'));
    }
    this.setState({ ctaDisabled: !isEmpty(missingRequiredFields) });
  }

  setFieldValue(path, value, isRequired) {
    const stt = this.state;
    set(stt, path, value);
    this.setState(stt, () => {
      if (this.state.ctaDisabled && isRequired) {
        this.updateRequiredStatus();
      }
    });
  }

  addressUpdateCallBack(address_id) {
    const cartId = get(this.props, 'booking.cart.id', '');
    if (cartId && address_id) {
      updateCart(cartId, { address_id }, get(this.props, 'fieldsHolder.csrfToken', ''), (resp) => {
        this.props.assignToCart({ cart: decode(resp.data.cart) });
        if (get(this.props, 'booking.cart.address.complete', false)) {
          const address_type = get(this.props, 'booking.cart.address.address_type', '');
          const parking_details = get(this.props, 'booking.cart.address.parking_info', '');
          const market = get(this.props, 'booking.cart.address.market', '');
          seBookingAddressDetails(address_type, parking_details, false, get(this.props, 'client.first_time_booker', false), 'booking_23_variant', 'booking_flow', market);
        }
        this.props.closeDrawer(true);
      }, (err) => {
        this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
      });
    } else {
      this.props.closeDrawer();
    }
  }

  submitDetailsAndNext() {
    const { ctaDisabled, newAddress } = this.state;
    if (!ctaDisabled) {
      if (!isEmpty(newAddress)) {
        updateAddress(get(this.props, 'booking.cart.address.id', ''), newAddress, (response) => {
          this.addressUpdateCallBack(get(response, 'data.address.id'));
        }, (err) => {
          this.props.displayError(get(err, 'response.data.errors.0.message', defaultErrorMessage));
        });
      } else {
        this.props.closeDrawer();
      }
    }
  }

  listViews() {
    const { display_array } = this.state;
    if (!isEmpty(display_array)) {
      return (map(sortBy(display_array, [function (o) { return o.display_type !== 'edittext_instructions'; }, function (o) { return o.key !== 'parking_info'; }]), (displayObj) => (
        this.showByRelevantType(displayObj)
      )));
    }
    return null;
  }

  displayInRadio(viewObject) {
    const {
      key, title, required, value, notes,
    } = viewObject;
    const choices = sortBy(get(viewObject, 'display_choices', []), ['display_order']);
    const formattedOptions = map(choices, (choice) => ({
      label: choice.title,
      value: choice.value,
      checked: choice.value === value,
      defaultValue: value,
    }));
    return (
      <div className="mb-24" key={`radio-${key}`}>
        <hr className="light-gray-hr" />
        <div className="size-24-32 font-weight-bold contentPrimary mt-24 mb-8">
          {title || ''}
          {required ? '*' : ''}
        </div>
        <div className="size-14-20 font-weight-bold contentPrimary mb-8">
          <RadioOptions
            name={key || ''}
            optionsArray={formattedOptions}
            onChange={(value1) => this.setFieldValue(`newAddress.${key}`, value1, required)}
            defaultValue={value}
            required={required}
          />
        </div>
        {notes ? <div className="size-12-20 color-light-gray">{notes}</div> : null}
      </div>
    );
  }

  displayInCheckbox(viewObject) {
    const choices = sortBy(get(viewObject, 'display_choices', []), ['display_order']);
    const {
      title, key, notes, required,
    } = viewObject;
    const formattedOptions = map(choices, (choice) => ({
      key: choice.key,
      label: choice.title,
      value: choice.value,
      checked: choice.is_selected,
    }));
    return (
      <div key={`checkbox-${key}`} className="">
        <hr className="light-gray-hr" />
        <div className=" mt-24 mb-8">
          <div className="size-24-32 font-weight-bold contentPrimary">
            {title || ''}
            {required ? '*' : ''}
          </div>
        </div>
        <div className="size-14-20 font-weight-bold contentPrimary mb-8">
          <CheckboxOptions
            optionsArray={formattedOptions}
            onChange={(key1, checked) => this.setFieldValue(`newAddress.${key1}`, Boolean(checked), required)}
          />
        </div>
        {notes ? <div className="size-12-20 color-light-gray">{notes}</div> : null}
      </div>
    );
  }

  displayInTextArea(viewObject) {
    const {
      key, value, required, title, placeholder, notes,
    } = viewObject;
    return (
      <div key={`textarea-${key}`} className="mb-24">
        <div className="size-24-32 font-weight-bold contentPrimary mb-8 mt-16">
          {title || ''}
          {required ? '*' : ''}
        </div>
        <div>
          <textarea
            className="form-control gray-textarea mb-8"
            required={required}
            rows={3}
            placeholder={placeholder || ''}
            defaultValue={value || ''}
            onChange={(event) => this.setFieldValue(`newAddress.${key}`, event.target.value || '', required)}
          />
        </div>
        {notes ? <div className="size-12-20 color-light-gray ">{notes}</div> : null}
      </div>
    );
  }

  showByRelevantType(viewObject) {
    const type = get(viewObject, 'display_type', '');
    if (type) {
      switch (type) {
        case 'radiobutton_list':
          return this.displayInRadio(viewObject);
        case 'checkbox_list':
          return this.displayInCheckbox(viewObject);
        case 'edittext_instructions':
          return this.displayInTextArea(viewObject);
        default:
          return;
      }
    }
    return null;
  }

  showMap() {
    const latitude = get(this.state, 'info_fields.latitude', '');
    const longitude = get(this.state, 'info_fields.longitude', '');
    if (latitude && longitude) {
      return (
        <div className="mb-20">
          <MapContainer lat={latitude} lng={longitude} />
        </div>
      );
    }
    return null;
  }

  showUpperSection() {
    const { display_headers, newAddress } = this.state;
    if (!isEmpty(get(display_headers, 'address_line_1.value', ''))) {
      const {
        address_line_1, apt_number, city, state, zip_code, country, address_line_2,
      } = display_headers;
      const tempArr = [
        get(city, 'value', ''),
        get(state, 'value', ''),
        get(zip_code, 'value', ''),
        get(country, 'value', '')];

      const formattedAddressLine = join(compact(tempArr), ', ');
      const aptNumberTitle = get(apt_number, 'additional_attributes.title', '');
      const aptNumber = get(apt_number, 'value', '');
      return (
        <>
          <div className="size-24-32 contentPrimary font-weight-bold mb-20">
            {get(address_line_1, 'value', '')}
            <br />
            {formattedAddressLine || ''}
            {!isEmpty(get(address_line_2, 'value', '')) ? <div className="size-14-20 color-gray">{get(address_line_2, 'value', '')}</div> : null}
          </div>
          <div className="mb-8">
            <div className="size-14-16 contentPrimary font-weight-bold mb-8">
              {aptNumberTitle}
              {' '}
              <span className="color-gray">(optional)</span>
            </div>
            <input
              className="form-control gray-input ht-36"
              defaultValue={aptNumber || ''}
              onChange={(event) => this.setFieldValue('newAddress.apt_number', event.target.value || '', get(apt_number, 'required', false))}
            />
          </div>
        </>
      );
    } if (!isEmpty(newAddress)) {
      const {
        address_line_1, apt_number, city, state, zip_code, country, address_line_2,
      } = newAddress;
      const formattedAddressLine = join([city, state, zip_code, country], ', ');
      const aptNumberTitle = get(display_headers, 'apt_number.additional_attributes.title', '');
      return (
        <>
          <div className="size-24-32 contentPrimary font-weight-bold mb-20">
            {address_line_1}
            <br />
            {formattedAddressLine || ''}
            {address_line_2 ? <div className="size-14-20 color-gray">{address_line_2}</div> : null}
          </div>
          <div className="mb-8">
            <div className="size-14-16 contentPrimary font-weight-bold mb-8">
              {aptNumberTitle}
              {' '}
              <span className="color-gray">(optional)</span>
            </div>
            <input
              className="form-control gray-input ht-36"
              defaultValue={apt_number || ''}
              onChange={(event) => this.setFieldValue('newAddress.apt_number', event.target.value || '', false)}
            />
          </div>
        </>
      );
    }
    return null;
  }

  showDetailsSection() {
    return (<div className="sm-border-btm">{this.listViews()}</div>);
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const { ctaDisabled } = this.state;
    return (
      <>
        <LatestModalDesign
          isOpen
          applyBtnCopy="Continue"
          footerExtraClass="br-top-none-imp"
          wrapperExtraClass={this.state.width <= 800 ? 'max-h-full-55' : ''}
          apply={this.submitDetailsAndNext}
          back={this.props.closeDrawer}
          disabled={ctaDisabled}
          hideCancel
          fullWidthFooter
          hideBackArrow={this.props.hideBackArrow}
        >
          <div className="display-flex">
            <div className="max-width-55">
              <div className="size-28-36 contentPrimary font-weight-bold mt-24 mb-32">Finish address details</div>
              <div className="mb-32">
                {this.showUpperSection()}
                {this.showDetailsSection()}
              </div>
            </div>
          </div>
        </LatestModalDesign>
        {this.errorModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: state.addresses,
  booking: state.booking,
  bookingFlow: state.bookingFlow,
  client: state.client,
  fieldsHolder: state.fieldsHolder,
});

export default withLocalize(connect(mapStateToProps, {
  setBookingFlowStep,
  setCart,
  setBookingFlowPreviousStep,
})(Index));
