import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  map, get, find, isEmpty, includes, size, camelCase,
} from 'lodash';
import { setCart } from '../../../Actions';
import StyledRadioTabs from '../../Shared/StyledRadioTabs';
import { isEventBooking } from '../../../constants';

class ProviderGender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedValues: [],
      selection: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (size(prevProps.optionIds) !== size(this.props.optionIds)) {
      const relvSlug = isEventBooking() ? "massage_event_gender" : "massage_gender";
      const pOptions = get(this.props, 'product.product_options', []);
      const prd = find(pOptions, (p) => (p.display_type === 'bubble' && p.slug === relvSlug));
      const options = get(prd, 'options', []);
      if (!isEmpty(options)) {
        let allowedValues = [];
        let sel = find(options, (op) => (includes(this.props.optionIds, op.id)));
        if (isEmpty(sel)) {
          sel = find(options, (op) => (op.default));
        }
        allowedValues = map(options, (o) => (o.id));
        this.setState({ allowedValues, selection: get(sel, 'id', null) }, () => {
          this.props.updateOption(get(sel, 'id', null), this.state.allowedValues, !get(prd, 'optional', false));
        });
      }
    }
  }

  render() {
    const relvSlug = isEventBooking() ? "massage_event_gender" : "massage_gender";
    const pOptions = get(this.props, 'product.product_options', []);
    const prd = find(pOptions, (p) => (p.display_type === 'bubble' && p.slug === relvSlug));
    const options = get(prd, 'options', []);
    const optional = get(prd, 'optional', false);
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const proFirstName = get(this.props, 'booking.cart.therapist_preferences.0.first_name', 'Your provider');
    const proId = get(this.props, 'booking.cart.therapist_preferences.0.id', '');
    if (isEmpty(prd)) return null;
    return (
      <div className="ptb-20 br-b-opaque d-flex-only justify-content-spaced align-items-center">
        <div className="size-16-20 color-black medium-font">{rebook ? "Provider" : get(prd, "title", "Provider")}</div>
        {rebook ? (
          <div>
            <StyledRadioTabs
              name="provider-gender-forced"
              optionsArray={[{ label: proFirstName, value: proId, checked: true }]}
            />
          </div>
        )
          : (
            <div>
              <StyledRadioTabs
                name="provider-gender"
                optionsArray={map(options, (el) => ({
                  label: el.title, value: el.id, checked: el.id === this.state.selection, relevantId: camelCase(this.props.isFirstCP ? get(el, 'slug', '') : `second_${get(el, 'slug', '')}`),
                }))}
                action={(val, title) => {
                  this.setState({ selection: Number(val) });
                  this.props.updateOption(val, this.state.allowedValues, !optional, title);
                }}
              />
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  booking: state.booking,
  client: state.client,
  bookingFlow: state.bookingFlow,
  product: state.booking.product,
});

ProviderGender.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  bookingFlow: PropTypes.object.isRequired,
  setCart: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setCart })(ProviderGender);
