/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  first, forEach, get, includes, isEmpty,
} from 'lodash';
import { withLocalize } from 'react-localize-redux';
import { setCart } from '../../../Actions';
import ColumnsList from '../../Shared/ColumnsList';
import { InfoOutlined } from '@material-ui/icons';
import LatestModalDesign from '../../Shared/LatestModalDesign';

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);

    this.renderBillingLineItems = this.renderBillingLineItems.bind(this);
    this.renderSecondBollingLineItems = this.renderSecondBollingLineItems.bind(this);
    this.tippingMessage = this.tippingMessage.bind(this);
    this.bookingFeeModal = this.bookingFeeModal.bind(this);

    this.state = {
      showBookingFeeModal: false,
    };
  }

  renderBillingLineItems() {
    const arr = [];
    const billing = get(this.props, 'booking.cart.billing', null);
    if (!isEmpty(billing)) {
      const { top_billing_line_items } = billing;

      forEach(top_billing_line_items, (item) => {
        let price; const
          strikedThroughText = item.strike_through_text ? <span className="gray-strike-through">{item.strike_through_text}</span> : null;
        if (`${item.amount}`.startsWith('- ')) {
          price = <span className="color-green">{item.amount}</span>;
        } else {
          price = (
            <span>
              {strikedThroughText}
              {' '}
              {item.amount}
            </span>
          );
        }

        if (!isEmpty(item.subtitles)) {
          forEach(item.subtitles, (subtitle, ind) => {
            if (ind === 0) {
              arr.push({ text: <span className='contentPrimary medium-font'>{item.title}</span> });
              arr.push({ text: subtitle, price });
            } else {
              arr.push({ text: '- ' + subtitle });
            }
          });
        } else {
          arr.push({ text: item.title, price });
        }
      });
    }
    return arr;
  }

  renderSecondBollingLineItems() {
    const arr = [];
    const billing = get(this.props, 'booking.cart.billing', null);
    if (!isEmpty(billing)) {
      const { bottom_billing_line_items } = billing;
      if (!isEmpty(bottom_billing_line_items)) {
        forEach(bottom_billing_line_items, (itm) => {
          if (includes(itm.title, 'Booking Fee')) {
            arr.push({
              text: <div className='d-flex-only align-items-center'>{itm.title}{" "}<InfoOutlined className="contentSecondary cursor-pointer w-h-20 ml-6" onClick={() => {
                this.setState({ showBookingFeeModal: true })
              }} /></div>, price: itm.amount
            });
          } else {
            arr.push({ text: itm.title, price: itm.amount });
          }
        });
      }
    }
    return arr;
  }
  bookingFeeModal() {
    return <LatestModalDesign
      contentExtraClass="modal-content-exception"
      footerExtraClass="sm-fix-exception"
      title="Appointment Booking Fee"
      subtitle="This fee helps cover essential technology and regulatory costs, ensuring a seamless booking experience while allowing independent providers to focus on delivering exceptional wellness services."
      isOpen={this.state.showBookingFeeModal}
      hideBackArrow
      hideCancel
      applyBtnCopy="Close"
      apply={() => this.setState({ showBookingFeeModal: false })}
    />;
  }

  tippingMessage() {
    const { cart } = this.props.booking;
    return cart && cart.cart_messages ? first(cart.cart_messages) : '';
  }

  render() {
    const price_with_tip = get(this.props, 'booking.cart.billing.price_with_tip', '');
    const isReviewStep = get(this.props, 'bookingFlow.step.id', '') === "REVIEW";

    return (
      <div>
        {isReviewStep ? null : <div className="second-title-style">Order Summary</div>}
        <div className="br-btm-gray-thin">
          <ColumnsList
            itemsList={this.renderBillingLineItems()}
          />
        </div>
        <div className="br-btm-gray-thin">
          <ColumnsList
            itemsList={this.renderSecondBollingLineItems()}
          />
        </div>
        <ColumnsList
          headerText="Due today"
          headerPrice={price_with_tip}
        />
        {this.bookingFeeModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  client: state.client,
  booking: state.booking,
  bookingFlow: state.bookingFlow,
});

OrderSummary.propTypes = {
  booking: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  setCart: PropTypes.func.isRequired,
};

export default withLocalize(connect(mapStateToProps, { setCart })(OrderSummary));
