/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
import {
  camelCase, find, get, isEmpty, map, size,
} from 'lodash';
import { Drawer } from '@material-ui/core';
import { setCart, setAddresses, setProducts } from '../../../Actions';
import ModalityStep from '../ModalityStep';
import { seBookingServiceSelect } from '../../Shared/WebAnalytics';
import { isEventBooking } from '../../../constants';
import ModalityStepForEvent from '../ModalityStep/ModalityStepForEvent';

class ServicesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      showErrorModal: false,
      openOption: false,
      width: window.innerWidth,
    };
    this.listProductServices = this.listProductServices.bind(this);
    this.selectProductBasedOnUserStatus = this.selectProductBasedOnUserStatus.bind(this);
    this.getPricesMember = this.getPricesMember.bind(this);
    this.getPricesNonMember = this.getPricesNonMember.bind(this);
    this.isFullCart = this.isFullCart.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  isFullCart() {
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const anoncrtPrds = get(this.props, 'anonymousCart.cartProducts', []);
    const crtPrds = get(this.props, 'booking.cart.cartProducts', []);
    const maxItems = isEventBooking() ? 1 : 2;
    if (loggedIn) {
      return size(crtPrds) >= maxItems;
    }
    return size(anoncrtPrds) >= maxItems;
  }

  selectProductBasedOnUserStatus(prd, opt) {
    const rebook = get(this.props, 'booking.cart.rebook', false);
    const crtPrds = get(this.props, 'booking.cart.cartProducts', []);
    const market = get(this.props, 'booking.cart.address.market', '');
    const loggedIn = get(this.props, 'client.loggedIn', false);
    const address = isEmpty(get(this.props, 'booking.cart.address', null)) ? get(this.props, 'fieldsHolder.listingPageStorage.address', null) : get(this.props, 'booking.cart.address', null);
    if (!isEmpty(address) && !this.isFullCart() && !(rebook && !isEmpty(crtPrds))) {
      this.props.assignToCart({ product: prd, currentOption: opt });
      seBookingServiceSelect(get(this.props, 'booking.cart', null), get(prd, 'title', '').toLowerCase(), get(this.props, 'client.first_time_booker', null), 'booking_23_variant', get(opt, 'title', ''), loggedIn, market, "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
      this.setState({ openOption: true });
    }
  }

  getPricesMember(opt) {
    const frm = get(opt, 'from_member_price', '');
    const to = get(opt, 'to_member_price', '');
    if (frm === to) {
      return frm;
    }
    return `${frm}-${to}`;
  }

  getPricesNonMember(opt) {
    const frm = get(opt, 'from_price', '');
    const to = get(opt, 'to_price', '');
    if (frm === to) {
      return frm;
    }
    return `${frm}-${to}`;
  }

  listProductServices() {
    const products = get(this.props, 'products', []);
    const loggedIn = get(this.props, 'client.loggedIn', false);
    if (isEmpty(products)) {
      return null;
    }
    return (
      <div data-spy="scroll" data-target="#navbar-example2" data-offset="0" className="p-40-64 sm-p-0-16">
        {map(products, (prd, ind) => {
          const propProdId = get(this.props, 'booking.product.id', '');
          const prdSz = size(get(this.props, 'booking.cart.cartProducts', []));
          const isSameProd = Boolean(!propProdId || prdSz === 0 || prd.id === propProdId);
          const rebook = get(this.props, 'booking.cart.rebook', false);
          const isFullCart = this.isFullCart() || (rebook && prdSz >= 1);
          const anonPrdSz = size(get(this.props, 'anonymousCart.cartProducts', []));
          const optns = get(find(get(prd, 'product_options', []), (ob) => (ob.display_type === 'detailed_radio')), 'options', []); const
            ln = size(optns);
          return (
            <div
              className={this.state.width <= 800 ? 'sm-pb-72 row' : 'row ml-0'}
              key={`prd-section-${ind}`}
              onMouseOver={() => this.props.updateHash(`#${get(prd, 'slug', '')}-services`)}
            >
              <div id={`${get(prd, 'slug', '')}-services`} className="hidden-anchor-96" />
              <div className={`col-xs-12 ${this.state.width < 800 ? '' : 'br-b-opaque prl-0'} ptb-8 ${this.props.scrolledDown ? 'mt-36' : 'mt-16'} `}>
                <div className=" size-24-32 medium-font">{get(prd, 'title', '')}</div>
                {isFullCart ? <div className="size-16-24 ">Your Cart is full. {isEventBooking() ? "Remove item to add another." : "Remove items to add more."}</div>
                  : isSameProd ? null
                    : <div className="size-16-24">Currently unavailable. Add up to two treatments from the same category</div>}
              </div>
              {map(optns, (opt, it) => {
                const isOfLast2Elements = it === ln - 1
                  || (it === ln - 2 && this.state.width > 800);
                const relevantSlugId = opt.slug;
                return (
                  <div
                    className={`col-xs-12 col-sm-6 
                    ${this.state.width <= 800 ? '' : it % 2 === 0 ? 'pl-0' : 'pr-0'} 
                    ${isSameProd && !isFullCart ? 'cursor-pointer' : 'opacity-05'}`}
                    key={`prd-opt-${ind}-${it}`}
                    onClick={() => {
                      if (isSameProd && !isFullCart) {
                        this.selectProductBasedOnUserStatus(prd, opt);
                      }
                    }}
                    id={camelCase((loggedIn && prdSz === 0)
                      || (!loggedIn && anonPrdSz === 0) ? relevantSlugId : `second_${relevantSlugId}`)}
                  >
                    <div className={`d-flex-only align-items-center ${isOfLast2Elements ? '' : 'br-b-opaque'}`}>
                      <div className="mtb-16 mr-16 flex-grow-1">
                        <div className="size-16-20 medium-font font-weight-bold contentPrimary mb-4">{get(opt, 'title', '')}</div>
                        <div className="size-14-20 contentSecondary mb-4 ellipsis-2 height-40">{get(opt, 'short_description', '')}</div>
                      </div>
                      <div className="mtb-16 ml-16">
                        <img
                          alt={`service-${relevantSlugId}`}
                          src={get(find(get(opt, 'images', []), (im) => (im.kind === 'card_display_image')), 'web_url')}
                          className="width-64 height-64 obj-fit-cover br-rd-4"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <>
        <Drawer style={{ zIndex: 999999999999 }} anchor="right" open={this.state.openOption} onClose={() => this.setState({ openOption: false })}>
          {isEventBooking() ?
            <ModalityStepForEvent
              closeDrawer={() => this.setState({ openOption: false })}
              assignToCart={this.props.assignToCart}
              displayError={this.props.displayError}
              setLoaderFlag={this.props.setLoaderFlag}
              showCart={this.props.showCart}
              next={this.props.next}
            /> :
            <ModalityStep
              closeDrawer={() => this.setState({ openOption: false })}
              assignToCart={this.props.assignToCart}
              displayError={this.props.displayError}
              setLoaderFlag={this.props.setLoaderFlag}
              showCart={this.props.showCart}
              jul_2024_booking_enhancements={get(this.props, "jul_2024_booking_enhancements", "")}
            />}
        </Drawer>
        {this.listProductServices()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  addresses: state.addresses,
  booking: state.booking,
  products: Object.values(get(state, 'productsReducer.products', {})),
  client: state.client,
  bookingFlow: state.bookingFlow,
  anonymousCart: state.anonymousCart,
  fieldsHolder: state.fieldsHolder,
});

export default connect(mapStateToProps, { setCart, setAddresses, setProducts })(ServicesList);
