/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import Edit from '@material-ui/icons/Edit';
import moment from 'moment';
import {
  get, map, split, size,
} from 'lodash';
import TextWithIcon from '../../Shared/TextWithIcon';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import DatetimeWrapper from '../../Shared/DatetimeWrapper';
import SelectTimeWrapper from '../../Shared/SelectTimeWrapper';
import { rescheduleAppointment } from '../Shared/helpers';
import { setAppointment } from '../../../Actions/AppointmentManagementAction';
import ErrorModal from '../../Shared/ErrorModal';
import { NOT_BEFORE_H, defaultErrorMessage } from '../../../constants';
import { momentCap } from '../Shared/constants';
import { decode } from '../Shared/encode';
import { seAppointmentReschedule } from '../../Shared/WebAnalytics';
import SuccesModal from './SuccessModal';

const yesterday = moment(new Date()).subtract(1, 'day');
const roundedUp = Math.ceil(moment(new Date()).minute() / 15) * 15;
class RescheduleAppt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionDate: '',
      sessionTime: '',
      isOpen: false,
      todayMinTime: moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0),
      todayMaxTime: moment(new Date()).set({
        hour: 22, minute: 0, second: 0, millisecond: 0,
      }),
      showErrorModal: false,
      error: null,
      showSuccessModal: false,
    };
    this.actionModal = this.actionModal.bind(this);
    this.submitReschedule = this.submitReschedule.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.errorModal = this.errorModal.bind(this);
    this.dateValue = this.dateValue.bind(this);
    this.timeValue = this.timeValue.bind(this);
    this.dayOf = this.dayOf.bind(this);
    this.onDayChange = this.onDayChange.bind(this);
    this.datePicker = this.datePicker.bind(this);
    this.feeMessage = this.feeMessage.bind(this);
    this.triggerRescheduleEvent = this.triggerRescheduleEvent.bind(this);
    this.refreshValues = this.refreshValues.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.refreshValues();
  }

  componentDidUpdate(prevProps) {
    const prevStt = get(prevProps, 'isOpenModal', false);
    const nextStt = get(this.props, 'isOpenModal', false);
    if (nextStt && prevStt !== nextStt) {
      this.refreshValues();
    }
  }

  refreshValues() {
    const sessionDate = this.dateValue();
    const sessionTime = this.timeValue(sessionDate);
    this.setState({
      sessionTime,
    }, () => {
      this.setState({
        todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
          hour: 8, minute: 0, second: 0, millisecond: 0,
        }),
        todayMaxTime: moment(this.state.sessionDate).set({
          hour: 22, minute: 0, second: 0, millisecond: 0,
        }),
      });
    });
  }

  dayOf() {
    const dateTime = this.state.sessionDate;
    return moment(new Date()).isSame(dateTime, 'day');
  }

  dateValue() {
    const dateUtc = get(this.props, 'appointment.date.utc', '');
    if (dateUtc) {
      const dateTime = moment(dateUtc);
      if (this.isValidDate(dateTime)) {
        return dateTime;
      }
    }
    return momentCap(new Date());
  }

  timeValue(sessionDate) {
    const timeUtc = get(this.props, 'appointment.time.utc', '');
    const timeD = get(this.props, 'appointment.time.display', '');
    if (timeUtc) {
      const hour24 = moment(`${sessionDate.format('YYYY-MM-DD')} ${timeD}`).format('HH:mm');
      let sessionDatetime = moment(`${sessionDate.format('YYYY-MM-DD')} ${timeD}`).add(NOT_BEFORE_H, 'h');
      const todaysCap = momentCap(sessionDate.toDate());
      const parts = split(hour24, ':', 2);
      if (Number(get(parts, '0', '')) > 20 || (Number(get(parts, '0', '')) === 20 && Number(get(parts, '1', '')) > 0)) {
        sessionDatetime = momentCap(moment(`${sessionDate.format('YYYY-MM-DD')}`).add(1, 'd').format());
      }
      // if the time is not avail for date then set to the min time for date
      if (todaysCap > sessionDatetime) {
        this.setState({ sessionDate: sessionDate.format('YYYY-MM-DD') });
        return todaysCap.format('hh:mm A');
      }
      this.setState({ sessionDate: sessionDatetime.format('YYYY-MM-DD') });
      return sessionDatetime.format('hh:mm A');
    }
    this.setState({ sessionDate: momentCap(new Date()).format('YYYY-MM-DD') });
    return momentCap(new Date()).format('hh:mm A');
  }

  triggerRescheduleEvent() {
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    const req_pros = get(this.props, 'appointment.therapist_preferences', []);
    map(cartProducts, (cp) => {
      const appointment_id = get(cp, 'id', '');
      const pro_id = get(cp, 'pro.id', 'n/a');
      const date = get(cp, 'session_date', '');
      const time = get(cp, 'session_time', '');
      if (appointment_id) {
        seAppointmentReschedule(appointment_id, pro_id, date, time, this.props.isComingFromInbox ? 'inbox_message' : 'appointment_details', size(req_pros), "jul_2024_booking_enhancements", get(this.props, "client.ab_tests.jul_2024_booking_enhancements", ""));
      }
    });
  }

  submitReschedule() {
    const cartId = get(this.props, 'appointment.id', '');
    const { sessionDate, sessionTime } = this.state;
    const is_event = get(this.props, 'appointment.is_event', false);
    if (!sessionDate || !sessionTime) {
      this.setState({ showErrorModal: true, error: 'Please select a date and time' });
      return;
    }
    if (is_event && moment(sessionDate, "YYYY-MM-DD").isBefore(moment().add(7, 'days'), "days")) {
      this.setState({ showErrorModal: true, error: 'Minimum event lead time is 7 days' });
      return;
    }
    rescheduleAppointment(cartId, {
      quoted_fee: get(this.props, 'appointment.fees.reschedule.amount', 0),
      rebook: 'same_therapist',
      session_date: sessionDate,
      session_time: moment(`${sessionDate} ${sessionTime}`, 'YYYY-MM-DD hh:mm A').format('HH:mm'),
    }, (response) => {
      this.props.setAppointment(decode(response.data.cart));
      this.triggerRescheduleEvent();
      this.setState({ isOpen: false, showSuccessModal: true });
    }, (err) => {
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  isValidDate(currentDate) {
    const { todayMinTime } = this.state;
    const { todayMaxTime } = this.state;
    const now = moment(new Date());

    if (currentDate.isSame(now, 'day')) {
      if (todayMaxTime.isBefore(todayMinTime)) {
        // There is not available slots today
        return false;
      }

      return now.isSameOrBefore(todayMaxTime) && currentDate.isSameOrBefore(momentCap(new Date()));
    }

    return currentDate.isAfter(yesterday);
  }

  onDayChange(day, dayString) {
    const sessionTime = moment(this.state.sessionTime, 'hh:mm A');
    const sessionDatetime = sessionTime.month(day.month()).date(day.date()).year(day.year());
    const todaysCap = momentCap(day.toDate());

    // if the time is not avail for date then set to the min time for date
    if (todaysCap > sessionDatetime) {
      this.setState({
        sessionDate: dayString, sessionTime: todaysCap.format('hh:mm A'),
      }, () => {
        this.setState({
          todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
            hour: 8, minute: 0, second: 0, millisecond: 0,
          }),
          todayMaxTime: moment(this.state.sessionDate).set({
            hour: 22, minute: 0, second: 0, millisecond: 0,
          }),
        });
      });
    } else {
      this.setState({ sessionDate: dayString }, () => {
        this.setState({
          todayMinTime: this.dayOf() ? moment(new Date()).add(NOT_BEFORE_H, 'hours').minutes(roundedUp).seconds(0) : moment(this.state.sessionDate).set({
            hour: 8, minute: 0, second: 0, millisecond: 0,
          }),
          todayMaxTime: moment(this.state.sessionDate).set({
            hour: 22, minute: 0, second: 0, millisecond: 0,
          }),
        });
      });
    }
  }

  datePicker() {
    return (
      <div className="mb-16">
        <DatetimeWrapper
          open
          input={false}
          value={this.state.sessionDate}
          timeFormat="YYYY-MM-DD"
          onChange={this.onDayChange}
          isValidDate={this.isValidDate}
        />
      </div>
    );
  }

  feeMessage() {
    const feeAmount = get(this.props, 'appointment.fees.reschedule.amount', 0);
    if (feeAmount) {
      return `You will be charged a fee of ${feeAmount}${get(this.props, 'appointment.currencySymbol', '$')}`;
    }
    return '';
  }

  actionModal() {
    const { isOpen, todayMaxTime, todayMinTime } = this.state;
    const { isOpenModal } = this.props;
    const hasMultiPros = size(get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', [])) > 1;

    return (
      <LatestModalDesign
        isOpen={isOpen || isOpenModal}
        popupId="reschedulePopup"
        disabled={todayMinTime.isAfter(todayMaxTime)}
        title={hasMultiPros ? 'Reschedule' : 'Reschedule appointment'}
        subtitle={hasMultiPros ? 'Your requested Providers will be notified of your new appointment request date and time.' : `Select a new date and time. ${this.feeMessage()}`}
        applyBtnCopy={hasMultiPros ? 'Reschedule Appointment' : 'Reschedule now'}
        relevantApplyId="rescheduleNowButton"
        apply={this.submitReschedule}
        close={() => this.setState({ isOpen: false }, this.props.hideRescheduleModal)}
      >
        <div className="max-width-300">
          {this.datePicker()}
          <div className="medium-font size-16-20 color-black mb-8">Time</div>
          <div className="gray-select-style">
            <SelectTimeWrapper
              date={this.state.sessionDate}
              time={this.state.sessionTime}
              minTime={todayMinTime.format('hh:mm A')}
              maxTime={todayMaxTime.format('hh:mm A')}
              onChange={(sessionTime) => { this.setState({ sessionTime }); }}
            />
          </div>
        </div>
      </LatestModalDesign>
    );
  }

  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    return (
      <>
        <div
          className="clickable"
          id="rescheduleAppointmentButton"
          onClick={() => { this.setState({ isOpen: true }, this.refreshValues); }}
        >
          <TextWithIcon
            muiIcon={<Edit />}
            title="Reschedule appointment"
            displayBorder={!get(this.props, "fromCancel", false)}
            displayArrow
            noPaddingForIcon={get(this.props, "fromCancel", false)}
          />
        </div>
        {this.actionModal()}
        {this.errorModal()}
        <SuccesModal
          isOpen={this.state.showSuccessModal}
          title="Your appointment is pending"
          subtitle="Your reschedule request has been sent to your requested Providers to confirm your appointment."
          customCTA={(
            <div className="centered">
              <button
                type="button"
                className="btn btn-accent-light"
                onClick={() => {
                  this.setState({ showSuccessModal: false });
                  this.props.hideRescheduleModal();
                }}
              >
                Done
              </button>
            </div>
          )}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
});
export default connect(mapStateToProps, { setAppointment })(RescheduleAppt);
