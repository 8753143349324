/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
    find, get, isEmpty, map, difference, forEach,
    filter, includes, size, head, cloneDeep, toNumber,
} from 'lodash';
import { Close } from '@material-ui/icons';
import {
    setCart, setBookingFlowPreviousStep,
    setAvailableTherapists, setAvailableTherapistsWithTimeSlots, setAnonymousCart,
    setField,
} from '../../../Actions';
import CTAButton from '../../Shared/CTAButton';
import ProviderGender from './ProviderGender';
import '../Assets/Styles/index.css';
import './Assets/Styles/ModalityStep.css';
import RecipientName from './RecipientName';
import { addCartProduct, deleteCartProduct, guestCreateCart, updateCartProduct } from '../Shared/helpers';
import { decode } from '../Shared/encode';
import { defaultErrorMessage, getB2bInfo } from '../../../constants';
import { seBookingDetails } from '../../Shared/WebAnalytics';
import Select from 'react-select';
import ErrorModal from '../../Shared/ErrorModal';

class ModalityStepForEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ctaDisabled: true,
            optionIds: [get(this.props, 'booking.currentOption.id', '')],
            clientName: get(this.props, 'client.first_name', 'Client'),
            submitted: false,
            gender: '',
            session: '',
            price: '',
            memberPrice: '',
            raw_price: 0,
            raw_member_price: 0,
            unavailablePros: false,
            unavailableProsMessage: '',
            showErrorModal: false,
            error: '',
        };
        this.listAppointmentsByClient = this.listAppointmentsByClient.bind(this);
        this.submitOptionsAndNext = this.submitOptionsAndNext.bind(this);
        this.updateOptionsByCartProduct = this.updateOptionsByCartProduct.bind(this);
        this.updateCTAStatus = this.updateCTAStatus.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
        this.showRelevantPrices = this.showRelevantPrices.bind(this);
        this.isMassageType = this.isMassageType.bind(this);
        this.getModalityTitle = this.getModalityTitle.bind(this);
        this.getDuration = this.getDuration.bind(this);
        this.getGender = this.getGender.bind(this);
        this.formatTitle = this.formatTitle.bind(this);
        this.formatSubtitle = this.formatSubtitle.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.setDefaultValues = this.setDefaultValues.bind(this);
        this.showRelevantPricesOldWay = this.showRelevantPricesOldWay.bind(this);
        this.createEventGuestCart = this.createEventGuestCart.bind(this);
        this.relevantBody = this.relevantBody.bind(this);
        this.existantCartFlow = this.existantCartFlow.bind(this);
    }
    // TODO revisit default options
    // error modal inside drawer
    // skipping add when error is returned for modality

    componentDidMount() {
        this.setDefaultValues();
    }

    setDefaultValues() {
        const tempArr = cloneDeep(this.state.optionIds);
        if (includes(get(this.props, 'booking.product.slug', ''), 'massage')) {
            const opts = filter(get(this.props, 'booking.product.product_options', []), (ci) => (ci.display_type === 'bubble'));
            if (!isEmpty(opts)) {
                forEach(opts, (optEl) => {
                    const defaultOption = find(get(optEl, 'options', []), (opp) => (opp.default));
                    if (defaultOption) {
                        tempArr.push(get(defaultOption, 'id', ''));
                    }
                });
                this.setState({ optionIds: tempArr });
            }
        } else {
            const {
                raw_member_price, raw_price, price, memberPrice,
            } = get(this.props, 'booking.currentOption', {});
            this.setState({
                raw_member_price, raw_price, price, memberPrice,
            });
        }
        this.updateCTAStatus(true);
    }
    //   {
    //     "from_price": null,
    //     "to_price": null,
    //     "from_member_price": null,
    //     "to_member_price": null,
    //     "id": 436,
    //     "slug": "massage_event_length_30",
    //     "title": "30 Min",
    //     "sub_title": null,
    //     "default": true,
    //     "session_length": 30,
    //     "short_description": null,
    //     "long_description": null,
    //     "detailed_description": null,
    //     "images": [],
    //     "price": "",
    //     "member_price": "",
    //     "raw_member_price": 0.0,
    //     "raw_price": 0.0,
    //     "rules": []
    // },
    isMassageType() {
        return get(this.props, 'product.slug', '') === 'massage';
    }

    getModalityTitle() {
        return get(this.props, 'booking.currentOption.title');
    }

    getDuration() {
        if (this.isMassageType()) {
            return get(this.state, 'session', '');
        }
        return '';
    }

    getGender() {
        if (this.isMassageType()) {
            return get(this.state, 'gender', '');
        }
        return '';
    }

    formatTitle() {
        const title = get(this.props, 'booking.currentOption.title', '');
        if (this.isMassageType()) {
            return `${this.getDuration()} ${title}`;
        }
        return title;
    }

    formatSubtitle() {
        if (this.isMassageType() && this.getGender()) {
            return `with a ${this.getGender()} Provider`;
        }
        return '';
    }

    getPrice() {
        const opt = get(this.state, 'product.opt', null);
        if (this.isMassageType()) {
            const sessions = get(this.state, 'product.sessions', []);
            const lngOpt = find(sessions, (op) => (get(this.state, 'options.sessions', '') === op.service_id));
            const total = get(lngOpt, 'rawPrice', '') ? Number(get(lngOpt, 'rawPrice', '')) + Number(getB2bInfo('parking_fee')) : 0;
            return `${get(lngOpt, 'currency', '')}${total || ''}`;
        }
        const subT = get(opt, 'rawPrice', '') ? Number(get(opt, 'rawPrice', '')) + Number(getB2bInfo('parking_fee')) : '';
        return `${get(opt, 'currency', '')}${subT || ''}`;
    }
    existantCartFlow() {
        const cartId = get(this.props, 'booking.cart.id', ''),
            cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', ''),
            currentProductId = get(this.props, 'booking.cart.cartProducts.0.product_id', ''),
            newProductId = get(this.props, 'product.id', '');
        if (Number(currentProductId) === Number(newProductId)) {
            updateCartProduct(cartId, cartProductId, {
                option_ids: this.state.optionIds,
            }, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
                this.props.assignToCart({ cart: decode(response2.data.cart) });
                const cp = get(this.props, `booking.cart.cartProducts.0`, {});
                seBookingDetails(decode(response2.data.cart), cp, get(this.props, 'product.title', '').toLowerCase(), this.props.client.first_time_booker, 'booking_23_variant', 1, get(cp, 'subtitle', ''), get(this.props, 'client.loggedIn', false), get(this.props, 'booking.cart.address.market', ''), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
                this.props.setLoaderFlag(false);
                this.props.next();
                this.props.closeDrawer();
            }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
        } else {
            deleteCartProduct(cartId, cartProductId, get(this.props, 'fieldsHolder.csrfToken', ''), (response) => {
                this.props.assignToCart({ cart: decode(response.data.cart) });
                addCartProduct(cartId, {
                    product_id: get(this.props, 'product.id', ''),
                    client_name: this.state.clientName,
                }, (respp) => {
                    this.props.assignToCart({ cart: decode(respp.data.cart) });
                    const relevantCartProduct = get(this.props, 'booking.cart.cartProducts.0', null);
                    updateCartProduct(cartId, get(relevantCartProduct, 'id', ''), {
                        option_ids: this.state.optionIds,
                    }, get(this.props, 'fieldsHolder.csrfToken', ''), (response2) => {
                        this.props.assignToCart({ cart: decode(response2.data.cart) });
                        const cp = get(this.props, `booking.cart.cartProducts.0`, {});
                        seBookingDetails(decode(response2.data.cart), cp, get(this.props, 'product.title', '').toLowerCase(), this.props.client.first_time_booker, 'booking_23_variant', 1, get(cp, 'subtitle', ''), get(this.props, 'client.loggedIn', false), get(this.props, 'booking.cart.address.market', ''), "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));
                        this.props.setLoaderFlag(false);
                        this.props.next();
                        this.props.closeDrawer();
                    }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
                }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
            }, (err) => this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage)));
        }
    }
    createEventGuestCart() {
        const { address, cart, user_kind } = get(this.props, 'fieldsHolder.listingPageStorage', {});
        const cartId = get(this.props, 'booking.cart.id', ''),
            cartProductId = get(this.props, 'booking.cart.cartProducts.0.id', '');
        this.props.setLoaderFlag(true);
        if (cartId && cartProductId && size(get(this.props, 'booking.cart.cartProducts', [])) == 1) {
            this.existantCartFlow();
        } else {
            guestCreateCart({
                ...cart,
                user_kind,
                product_id: get(this.props, 'product.id', ''),
                cart_products: [{
                    option_ids: this.state.optionIds,
                    client_name: this.state.clientName,
                }],
            }, address,
                get(this.props, 'fieldsHolder.csrfToken', ''),
                (response) => {
                    this.props.assignToCart({ cart: decode(response.data.cart) });
                    this.props.setLoaderFlag(false);
                    this.props.next();
                    this.props.closeDrawer();
                },
                (err) => {
                    this.props.setLoaderFlag(false);
                    const { code, message } = get(err, 'response.data.errors.0', {});
                    if (code === "unavailable_providers") {
                        this.setState({ unavailablePros: true, unavailableProsMessage: message });
                        return;
                    }
                    this.errorHandler(get(err, 'response.data.errors.0.message', defaultErrorMessage));
                },
            );
        }
    }
    submitOptionsAndNext(skipNext = false) {
        if (!skipNext) {
            this.props.setLoaderFlag(true);
            this.setState({ submitted: true });
        }
        const {
            optionIds, clientName, price,
        } = this.state;
        const loggedIn = get(this.props, 'client.loggedIn', false);
        const anonCart = get(this.props, 'anonymousCart', {});
        let cps = get(anonCart, 'cartProducts', []);
        const market = get(this.props, 'anonymousCart.address.market', '') || get(this.props, 'booking.cart.address.market', '');

        const cp = {
            optionIds, clientName, title: this.formatTitle(), subtitle: this.formatSubtitle(), price,
        };
        seBookingDetails(get(this.props, 'booking.cart', {}), cp, get(this.props, 'product.title', '').toLowerCase(), get(this.props, 'client.first_time_booker', null), 'booking_23_variant', size(cps), this.getGender(), loggedIn, market, "jul_2024_booking_enhancements", get(this.props, "jul_2024_booking_enhancements", ""));

        cps = [cp];

        this.props.setLoaderFlag(false);
        this.createEventGuestCart();
    }

    showRelevantPrices() {
        const { price, quantity, raw_price } = this.state;
        const loggedIn = get(this.props, 'client.loggedIn', false);
        const total = raw_price * quantity;
        const currSymbol = get(this.props, 'booking.cart.currency_symbol', '') || toNumber(head(price)) ? head(price) : '$';
        if (total === 0) {
            return '';
        }
        if (loggedIn) {
            const isSoothePassSubscribed = get(this.props, 'booking.cart.info_fields.soothe_pass.subscribed', false) || get(this.props, 'client.soothe_pass.soothe_pass_subscribed', false);
            if ((!isSoothePassSubscribed) && price) {
                return ` — ${currSymbol}${total}`;
            }
        } else if (price) {
            return ` — ${currSymbol}${total}`;
        }
        return '';
    }

    showRelevantPricesOldWay() {
        const {
            price, raw_price,
        } = this.state;
        const currSymbol = get(this.props, 'booking.cart.currency_symbol', '') || toNumber(head(price)) ? head(price) : '$';
        return { content: raw_price ? `${currSymbol}${raw_price}` : "", direction: 'left' };
    }
    updateCTAStatus(skipp = false) {
        const prdId = get(this.props, 'product.id', '');
        const { optionIds, clientName } = this.state;
        this.setState({ ctaDisabled: false }, () => {
            if (prdId) {
                if (isEmpty(clientName) || isEmpty(optionIds) || (this.isMassageType() && ((size(optionIds) < 3 && !get(this.props, 'booking.cart.therapist_preferences.0.id', '')) || (size(optionIds) < 2 && get(this.props, 'booking.cart.therapist_preferences.0.id', '')))) || (!this.isMassageType() && size(optionIds) < 2)) {
                    this.setState({ ctaDisabled: true });
                    return false;
                }
                this.setState({ ctaDisabled: false });
            }
            if (!skipp) {
                this.submitOptionsAndNext(true);
            }
        });
    }

    updateOptionsByCartProduct(optionId, allowedValues, required, title, field) {
        if (optionId) {
            const { optionIds } = this.state;
            const newArray = difference(optionIds, allowedValues);
            newArray.push(optionId);
            this.setState({ optionIds: newArray, [field]: title }, () => {
                if (required) {
                    this.updateCTAStatus(true);
                }
            });
        }
    }
    relevantBody() {
        const loggedIn = get(this.props, 'client.loggedIn', false);
        const cart_products = !loggedIn ? get(this.props, 'anonymousCart.cartProducts', []) : get(this.props, 'booking.cart.cartProducts', []);
        const isFirstCP = isEmpty(cart_products);
        const { clientName, optionIds } = this.state;
        const pOptions = get(this.props, 'product.product_options', []);
        const prd = find(pOptions, (p) => (p.display_type === 'bubble' && p.slug === 'massage_event_length'));
        const options = get(prd, 'options', []);
        const slotOptions = map(options, (el) => ({
            label: el.title, value: el.id
        }))
        const { unavailablePros, unavailableProsMessage } = this.state;
        if (unavailablePros) {
            const [first, rest] = unavailableProsMessage.split('on', 2);
            const [part1, part2] = rest.split('from', 2);
            return (<>
                <div className="size-16-24 contentPrimary mb-32 centered mt-24">
                    <div className='mb-16'>{first}{' '}<br />on{' '}
                        <span className="font-weight-bold">{part1}</span>{' '}from{' '}
                        <span className='font-weight-bold'>{part2}</span>
                    </div>
                    <div>Please select a different service or try another date and/or time.</div>
                </div>
                <div className='d-flex-only justify-content-centered'>
                    <div>
                        <button className='btn md-cta-quaternary mr-10'
                            onClick={() => {
                                this.setState({ unavailablePros: false, unavailableProsMessage: '' }, () => {
                                    this.props.closeDrawer();
                                })

                            }}>Change Service</button>
                    </div>
                    <div>
                        <button className='btn md-cta-tertiary p-10-12-imp'
                            onClick={() => {
                                this.setState({ unavailablePros: false, unavailableProsMessage: '' }, () => {
                                    const parsedParams = queryString.parse(window.location.search);
                                    window.location.href = `/events-booking?event-organizer=${parsedParams['event-organizer']}`;
                                })
                            }}
                        >Change Date/Time</button>
                    </div>
                </div>
            </>
            )
        }
        return <>
            <div className="ptb-20 br-b-opaque d-flex-only justify-content-spaced align-items-center">
                <div className="size-16-20 color-black medium-font">{get(prd, "title", "Session Length")}</div>
                <div>
                    <Select
                        className="generic-selector-style-white"
                        classNamePrefix="select"
                        value={find(slotOptions, (el) => includes(optionIds, el.value))}
                        options={slotOptions}
                        isSearchable={false}
                        onChange={(val) =>
                            this.updateOptionsByCartProduct(Number(val.value), slotOptions.map((el) => el.value), true, val.label, 'session')}
                    />
                </div>
            </div>
            <ProviderGender
                optionIds={optionIds}
                isFirstCP={isFirstCP}
                updateOption={(optionId, allowedValues, required, title) => {
                    this.updateOptionsByCartProduct(Number(optionId), allowedValues, required, title, 'gender');
                }}
            />
            <RecipientName
                defaultValue={clientName || ''}
                isFirstCP={isFirstCP}
                updateClientName={(field, clientName) => this.setState(
                    { [field]: clientName },
                    () => this.updateCTAStatus(true),
                )}
                quantity={this.state.quantity}
            />
        </>
    }
    listAppointmentsByClient() {
        const option = get(this.props, 'booking.currentOption', {});
        return (
            <div className="mb-100 p-16">
                <div className="font-weight-bold size-24-32 contentPrimary mb-4">{get(option, 'title', '')}</div>
                <div className="size-14-20 contentSecondary mb-16">{get(option, 'long_description', '')}</div>

                {this.relevantBody()}
            </div>
        );
    }

    errorHandler(error) {
        this.props.setLoaderFlag(false);
        this.setState({ submitted: false, showErrorModal: true, error });
    }

    errorModal() {
        return (
            <ErrorModal
                isOpen={this.state.showErrorModal}
                close={() => { this.setState({ showErrorModal: false, error: null }); }}
            >
                <p>{this.state.error}</p>
            </ErrorModal>
        );
    }
    render() {
        const { ctaDisabled, unavailablePros } = this.state;

        return (<>
            <div className="width-464 sm-w-100">
                <div className="max-h-full-90 overflow-y-scroll">
                    <div className="bg-cover height-320" style={{ backgroundImage: `url(${get(find(get(get(this.props, 'booking.currentOption', {}), 'images', []), (im) => (im.kind === 'card_display_image')), 'web_url')})` }}>
                        <button
                            type="button"
                            className="btn contentPrimary br-rd-48 fixed-btn-shadow-simple bg-primary abs-top m-12 d-flex-only align-items-center w-h-48 justify-content-centered"
                            onClick={this.props.closeDrawer}
                        >
                            <Close style={{ fontSize: '14px' }} />
                        </button>
                    </div>
                    {this.listAppointmentsByClient()}
                </div>
                <CTAButton
                    text="Add to cart"
                    disabled={ctaDisabled || unavailablePros}
                    addon={this.showRelevantPricesOldWay()}
                    submitted={this.state.submitted}
                    action={this.submitOptionsAndNext}
                    forceLeft
                    additionalClass="width-auto"
                    additionalWrapperClass="justify-content-spaced sm-display-flex"
                    relevantId="continueButton"
                />
            </div>
            {this.errorModal()}
        </>);
    }
}

const mapStateToProps = (state) => ({
    booking: state.booking,
    client: state.client,
    bookingFlow: state.bookingFlow,
    product: state.booking?.product,
    anonymousCart: state.anonymousCart,
    fieldsHolder: state.fieldsHolder,
});


export default connect(mapStateToProps, {
    setCart,
    setBookingFlowPreviousStep,
    setAvailableTherapists,
    setAvailableTherapistsWithTimeSlots,
    setAnonymousCart,
    setField,
})(ModalityStepForEvent);