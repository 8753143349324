
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import "./index.css";
import EventForm from './EventForm';
import HelmetTag from '../Shared/HelmetTag';
import NavbarRedesignForMarketplace from '../Shared/NavbarRedesignForMarketplace';
import { isEventBooking } from '../../constants';
import { getGuestCart } from '../BookingFlowForMarketplace/Shared/helpers';
import { get } from 'lodash';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainEvent: {},
        };
    }
    componentDidMount() {
        if (isEventBooking()) {
            const parsedParams = queryString.parse(window.location.search);
            getGuestCart(parsedParams['event-organizer'], (response_1) => {
                const cart_1 = get(response_1, 'data.carts.cart', null);
                if (cart_1) {
                    this.setState({ mainEvent: cart_1, });
                }
            }, (error) => {
                console.log({ error });
            });

        }
        // I need to know what endpoints to use here if
        // TODO should I support 2 cases here (guest and loggedin user)?
        // happens only going backwards in the flow ? or by url param passed
    }
    render() {
        return (
            <div>
                <HelmetTag />

                <NavbarRedesignForMarketplace
                    showMenu
                    goToAuthentication
                />
                <div className="container mt-big">
                    {/* <div className="maxW-75-vw"> */}
                    <EventForm {...this.state.mainEvent} />
                    {/* TODO container need to be fixed*/}
                    {/* </div> */}
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    client: state.client,
});
export default connect(mapStateToProps, {})(Index);