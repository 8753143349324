import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { defaultErrorMessage, HotJar } from '../../constants';
import "./index.css";
import PlaceAutoCompleteInput from '../Shared/PlaceAutoCompleteInput';
import PlantSvg from '../../Assets/Images/event_plant.svg';
import BlueCheckLottie from '../Shared/BlueCheckLottie';
import Datetime from 'react-datetime';
import moment from 'moment';
import SelectTimeWrapper from '../Shared/SelectTimeWrapper';
import CTAButton from '../Shared/CTAButton';
import { contactRepresentative, initCartJson } from '../Shared/Helpers';
import CheckboxOptions from '../Shared/CheckboxOptions';
import CenteredGrids from '../Shared/CenteredGrids';
import EmailInput from '../Shared/EmailInput';
import PhoneInput from '../Shared/PhoneInput';
import { get, isEmpty, pick } from 'lodash';
import LoadingOverlay from '../Shared/LoadingOverlay';
import ErrorModal from '../Shared/ErrorModal';

HotJar();

class EventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
            showErrorModal: false,
            width: window.innerWidth,
            loading: false,
            duration: 0,

            showForm: true,
            needARep: false,
            emailIsValid: false,
            phoneIsValid: false,
            date: "",
            dateIsInvalid: "",
            endTimeIsInvalid: "",

            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            occasion: "",
            start_time: "",
            end_time: "",
            address: {
                address_line_1: "",
                address_line_2: "",
                city: "",
                state: "",
                zip_code: "",
                country: "",
            },
            no_pros: 0,
            guest_no: 0,
            kind: "personal",
            company: "",
        };
        this.setValueByField = this.setValueByField.bind(this);
        this.thankYouView = this.thankYouView.bind(this);
        this.formView = this.formView.bind(this);
        this.continueFlowWithRepresentative = this.continueFlowWithRepresentative.bind(this);
        this.continueFlowWithoutRespresentative = this.continueFlowWithoutRespresentative.bind(this);
        this.validateAndsubmitForm = this.validateAndsubmitForm.bind(this);
        this.errorModal = this.errorModal.bind(this);
        this.isValidDate = this.isValidDate.bind(this);
        this.isValidTime = this.isValidTime.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth })
    }
    componentDidUpdate(prevProps) {
        // TODO maybe add a check for checkedout carts of current uuid
        if (!prevProps.user_kind && this.props.user_kind) {
            console.log("EventForm props", this.props);
            this.setState({
                ...pick(this.props, ["first_name", "last_name", "email", "phone", "occasion", "start_time", "end_time", "no_pros", "guest_no", "kind", "company", "date"]),
                needARep: Boolean(this.props.first_name),
                start_time: moment(this.props.session_time, 'HH:mm').format('H:mm A'),
                end_time: moment(this.props.session_end_time, 'HH:mm').format('H:mm A'),
                date: moment(this.props.session_date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
                guest_no: Number(this.props.guest_no),
                no_pros: Number(this.props.no_pros),
                address: {
                    ...this.props.address,
                    zip_code: this.props.zipCode,
                }
            });
        }
    }
    setValueByField(field, value) {
        this.setState({ [field]: value });
    }
    isValidDate(currentDate) {
        let sevenDaysFromNow = moment().add(7, 'days');
        let selectedDate = moment(currentDate);
        return selectedDate.isSameOrAfter(sevenDaysFromNow, "days") ? "" : "invalid";
    }
    isValidTime() {
        let { end_time, start_time } = this.state;
        if (!end_time || !start_time) return "";
        let startTime = moment(start_time, 'hh:mm A');
        let endTime = moment(end_time, 'hh:mm A');
        let diff = endTime.diff(startTime, 'hours');
        this.setState({ duration: endTime.isBefore(startTime) ? -1 : diff });
        return diff >= 3 && diff <= 8 ? "" : "invalid";
    }

    continueFlowWithRepresentative() {
        this.setState({ loading: true });
        let payload = pick(this.state, ["first_name", "last_name", "email", "phone", "occasion", "start_time", "end_time", "no_pros", "guest_no", "kind", "company", "date"]);
        contactRepresentative(
            {
                ...payload,
                ...this.state.address,
            },
            (_response) => {
                this.setState({ loading: false, showForm: false });
            },
            (error) => {
                const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
                this.setState({ loading: false, showErrorModal: true, error: message });
            }
        );
    }
    continueFlowWithoutRespresentative() {
        let { first_name, last_name, email, phone, occasion, start_time, end_time, address, no_pros, guest_no, kind, company, date } = this.state;
        let cart_json = {
            // TODO revisit time format
            cart: {
                "user_kind": "guest",
                occasion,
                no_pros,
                guest_no,
                kind,
                company,
                session_date: moment(date, "MM/DD/YYYY").format('YYYY-MM-DD'),
                session_time: moment(start_time, 'hh:mm A').format('HH:mm'),
                session_end_time: moment(end_time, 'hh:mm A').format('HH:mm'),
                event: true,
            },
            address: {
                ...address,
                zipCode: address.zip_code,
            },
        }
        initCartJson(cart_json, (response) => {
            let uuid = get(response, "data.uuid", "");
            if (uuid) {
                window.location.href = `/booking?event-organizer=${uuid}`;
            }
        }, (error) => {
            const message = get(error, 'response.data.errors.0.message', defaultErrorMessage);
            this.setState({ showErrorModal: true, error: message });
        });
    }
    validateAndsubmitForm() {
        let { first_name, last_name, email, phone, occasion, start_time, end_time, address, no_pros, guest_no, kind, company, date, needARep,
            dateIsInvalid, endTimeIsInvalid, emailIsValid, phoneIsValid } = this.state;
        let { address_line_1, city, state, zip_code, country } = address;
        if (!occasion || !start_time || !end_time || !no_pros || !guest_no || !kind || !date || dateIsInvalid || endTimeIsInvalid || !address_line_1 || !city || !state || !zip_code || !country) {
            this.setState({ showErrorModal: true, error: "Please fill all required fields" });
            return;
        }
        if (kind === "corporate" && !company) {
            this.setState({ showErrorModal: true, error: "Please fill in a company name" });
            return;
        }
        if (needARep && (!first_name || !last_name || !email || !phone)) {
            this.setState({ showErrorModal: true, error: "Please fill all required fields" });
            return;
        }
        if (needARep && (!emailIsValid || !phoneIsValid)) {
            this.setState({ showErrorModal: true, error: `Please fill a valid ${!emailIsValid ? "email" : "phone number"}` });
            return;
        }
        if (needARep) {
            this.continueFlowWithRepresentative();
        } else {
            this.continueFlowWithoutRespresentative();
        }
    }

    thankYouView() {
        return (<div className='flex-centered ht-100'>
            <div className='centered'>
                <div className='centered'><BlueCheckLottie /></div>
                <div className='size-44-52 medium-font color-black mb-16'>Thank you!</div>
                <p className='size-18-28 color-black mb-32'>We've received your event inquiry.<br />
                    A Soothe representative will be in touch with you in 1-2 business days. </p>
                <CTAButton
                    text="Browse Services"
                    action={() => {
                        window.location.href = '/booking/';
                    }}
                    additionalClass="resp-w-303"
                    additionalWrapperClass="justify-content-center br-top-none-imp mb-100"
                    relevantId="browseServicesButton"
                />
            </div>
        </div>
        )
    }
    formView() {
        let {
            showForm, needARep, date, first_name, last_name, email, emailIsValid, phone, phoneIsValid, occasion, start_time, end_time, address, address_line_1, address_line_2, city, state, zip_code, country, no_pros, guest_no, kind, company,
            dateIsInvalid, endTimeIsInvalid, width, duration
        } = this.state;
        return (<>
            <div className='mb-16'>

                <div className='size-44-52 medium-font color-black mb-16'>Build your wellness event</div>
                <p className='size-18-28 color-black'>
                    Please answer a few questions to get started. Event duration must be between 3 to 8 hours.
                </p>
            </div>



            <form className='mt-16' onSubmit={(e) => { e.preventDefault() }}>

                {/* Event Type */}
                <div className="mb-24">
                    <div className="mb-16 medium-font color-black size-14-16">Event type</div>
                    <CenteredGrids additionalClassName="small-centered-grids"
                        grids={[
                            {
                                title: 'Corporate',
                                value: "corporate",
                                action: () => this.setValueByField("kind", "corporate"),
                                selected: kind === "corporate",
                            }, {
                                title: 'Personal',
                                value: "personal",
                                action: () => this.setValueByField("kind", "personal"),
                                selected: kind === "personal",
                            }]} />
                </div>
                {/* Ill need to retrigger validation checks ma3neha */}
                {kind === "corporate" ? <div className="mb-24">
                    <div className="mb-10 medium-font color-black size-14-16">Company Name</div>
                    <input
                        defaultValue={company}
                        required
                        type="text"
                        className="form-control generic-input-style-white"
                        onChange={(e) => this.setValueByField("company", e.target.value)}
                    />
                </div> : null}
                {/* Occasion */}
                <div className="mb-24">
                    <div className="mb-10 medium-font color-black size-14-16">Occasion</div>
                    <input
                        defaultValue={occasion}
                        type="text"
                        className="form-control generic-input-style-white"
                        placeholder="e.g., Bachelorette Party"
                        onChange={(e) => this.setValueByField("occasion", e.target.value)}
                    />
                </div>

                {/* Date */}
                <div className="mb-24">
                    <div className="mb-10 medium-font color-black size-14-16">
                        Date
                    </div>
                    <Datetime className={`generic-datepicker-style-white ${dateIsInvalid}`}
                        timeFormat={false}
                        dateFormat="MM/DD/YYYY"
                        value={date}
                        onChange={(e) => {
                            this.setState({
                                date: e.format("MM/DD/YYYY"),
                                dateIsInvalid: this.isValidDate(e)
                            });
                        }}
                        closeOnSelect
                    />
                    <div className='invalid-msg size-14-20'>Minimum event lead time is 7 days.</div>
                </div>
                <div className='row'>
                    {/* Start and End Time */}
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">
                            Start time
                        </div>
                        <div className="gray-select-style">
                            <SelectTimeWrapper
                                customClassName="generic-selector-style-white"
                                date={date}
                                time={start_time}
                                minTime="08:00 AM"
                                maxTime="10:00 PM"
                                onChange={(start_time) => {
                                    this.setState({ start_time }, () => {
                                        this.setState({ endTimeIsInvalid: this.isValidTime() ? "invalid" : "" })
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">
                            End time
                        </div>
                        <SelectTimeWrapper
                            customClassName={`generic-selector-style-white ${endTimeIsInvalid}`}
                            date={date}
                            time={end_time}
                            minTime={start_time}
                            maxTime="10:00 PM"
                            onChange={(end_time) => {
                                this.setState({ end_time }, () => {
                                    this.setState({ endTimeIsInvalid: this.isValidTime() ? "invalid" : "" })
                                })
                            }}
                        />
                        {duration < 0 ? <div className='invalid-msg size-14-20'>End time must be after start time.</div> : null}
                        {duration < 3 && duration >= 0 ? <div className='invalid-msg size-14-20'>Minimum event duration is 3 hours.</div> : null}
                        {duration > 8 ? <div className='invalid-msg size-14-20'>Maximum event duration is 8 hours.</div> : null}
                    </div>

                    {/* Number of Providers and Guests */}
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">
                            Number of Providers needed
                        </div>
                        <input type="number" className="form-control generic-input-style-white"
                            value={no_pros}
                            onChange={(e) => this.setValueByField("no_pros", e.target.value)}
                            onWheel={(e) => e.target.blur()}
                        />
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">
                            Estimated number of guests
                        </div>
                        <input type="number" className="form-control generic-input-style-white"
                            value={guest_no}
                            onChange={(e) => this.setValueByField("guest_no", e.target.value)}
                            onWheel={(e) => e.target.blur()}
                        />
                    </div>

                </div>
                {/* Address */}
                <div className='mb-16'>
                    <label className='address-form-label'>Event Address</label>
                    <PlaceAutoCompleteInput
                        uniqueId={"event-address-one-off"}
                        inputStylingClass="form-control generic-input-style-white"
                        clearBtnStylingClass="address-clear-input"
                        handleAddress={(newAddress) => {
                            const { addressLine1, country, state, zipCode, name, city } = newAddress;
                            this.setValueByField("address", {
                                address_line_1: addressLine1,
                                country,
                                state,
                                zip_code: zipCode,
                                city,
                                // name
                            });
                        }}
                        value={address?.address_line_1 || ''}
                        placeholder={address?.address_line_1 || ''}
                        onClear={() => {
                            this.setValueByField("address", {});
                        }}
                        hideIcon
                    // disabled={get(this.props, 'id', "") || true}
                    // TODO disable editing address ability here if cart already created
                    />
                </div>

                <div className='mb-24'>
                    <CheckboxOptions optionsArray={[{
                        key: "needARep_key",
                        label: <div className='size-14-20 color-black regular-font'>Check this box if you'd like a Soothe representative to reach out.</div>,
                        value: needARep,
                        checked: needARep,
                    }]}
                        onChange={(key1, checked) => this.setValueByField("needARep", checked)} />
                </div>
                {!needARep ? null : <div className='row'>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">First Name</div>
                        <input
                            defaultValue={first_name}
                            type="text"
                            className="form-control generic-input-style-white"
                            onChange={(e) => this.setValueByField("first_name", e.target.value)}
                        />
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">Last Name</div>
                        <input
                            defaultValue={last_name}
                            type="text"
                            className="form-control generic-input-style-white"
                            onChange={(e) => this.setValueByField("last_name", e.target.value)}
                        />
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">Email</div>
                        <EmailInput className={`form-control generic-input-style-white ${!emailIsValid && email ? 'invalid' : ''}`}
                            email={email}
                            onEmailChange={({ email, isValid }) => this.setState({
                                email,
                                emailIsValid: isValid
                            })} />
                    </div>
                    <div className="col-xs-6 mb-24">
                        <div className="mb-10 medium-font color-black size-14-16">Phone Number</div>
                        <PhoneInput
                            showLable={false}
                            onPhoneNumberChange={({ number, isValidNumber }) => {
                                this.setState({ phone: number, phoneIsValid: isValidNumber });
                            }}
                            className={`intl-tel-input generic-input-style-white size-14-20 contentPrimary z-index-0 ${!phoneIsValid && phone ? 'invalid' : ''}`}
                            phone={phone}
                        />
                    </div>
                </div>}

                <div className='continue-btn-container mt-10'>
                    <CTAButton
                        text="Next"
                        action={this.validateAndsubmitForm}
                        additionalClass="resp-w-303"
                        additionalWrapperClass={`justify-content-center br-top-none-imp ${width > 800 ? "" : "b-55-imp"}`}
                    />
                </div>
            </form>
        </>
        );
    }
    errorModal() {
        return (
            <ErrorModal
                isOpen={this.state.showErrorModal}
                close={() => { this.setState({ showErrorModal: false, error: null }); }}
            >
                <p>{this.state.error}</p>
            </ErrorModal>
        );
    }

    render() {
        let { showForm, loading, width } = this.state;
        return <>
            <div className={width > 800 ? 'pt-80' : 'mb-150'}>
                <div className='row'>
                    {/* {smallScreen ? null : <div className="flex-2 d-sm-none full-w-h bg-img" style={{ backgroundImage: `url(${get(this.props, 'appointment.info_fields.img_url', '')})` }} />} */}
                    <div className='sm-d-none col-sm-5'>
                        <img width="100%" height="100%" className='obj-fit-cover'
                            src={PlantSvg} alt="plant bg"
                        />
                    </div>
                    <div className='col-xs-12 col-sm-7'>
                        {showForm ? this.formView()
                            : this.thankYouView()}
                    </div>
                </div>
                {this.errorModal()}
            </div>

            <LoadingOverlay showLoader={loading} />
        </>
    }

}
const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
})(EventForm);