/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/sort-comp */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import {
  AddCircleOutline, ArrowBack, Group,
  GroupAdd, Message, Replay, Star,
} from '@material-ui/icons';
import {
  map, filter, isEmpty, get, size, compact, includes, find, assign,
} from 'lodash';
import { Redirect } from 'react-router-dom';
import { AvatarGroup } from '@material-ui/lab';
import { Avatar } from '@material-ui/core';
import moment from 'moment-timezone';
import TextWithIcon from '../../Shared/TextWithIcon';
import {
  createRebookCart, getAvailableTimesOfPro, getProPrivateProfile, openChat,
} from '../Shared/helpers';
import AddressList from './AddressList';
import {
  seInboxViewMessage, seProviderContact, seRequestedProToasterView, seViewAlternativeTimes,
} from '../../Shared/WebAnalytics';
import {
  fetchChat, setCart, setABTest, setRebookOptions, setAppointment,
} from '../../../Actions';
import { HOST_ROOT } from '../../../apiConfig';
import { ROUTES, defaultErrorMessage, dynamicCopyForDefaultBlindAuction } from '../../../constants';
import { getSingleChatState } from '../Shared/constants';
import { getClientById, getRecommendedPros } from '../../Shared/Helpers';
import { decode } from '../Shared/encode';
import ProProfilePopup from '../../Providers/ProProfilePopup';
import placeholder from '../../../Assets/Images/placeholder.png';
import LatestModalDesign from '../../Shared/LatestModalDesign';
import AvailableTimesModal from './AvailableTimesModal';
import ProListDrawer from '../../BookingFlowForMarketplace/ProviderPickStepMarketplace/ProListDrawer';
import { updateCart } from '../../BookingFlowForMarketplace/Shared/helpers';
import ErrorModal from '../../Shared/ErrorModal';

class ProviderOfAppt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      providerName: '',
      isAddressModalOpen: get(props, 'openRelevantModal', false) && props.abTest !== 'new_flow',
      providerId:  get(props, 'openRelevantModal', false) && props.abTest !== 'new_flow' && get(props, 'location.state.providerId', ''),
      redirect: false,
      abAssign: props.abTest,
      showProsList: get(this.props, 'prosPopupOpen', false),
      isOpenAvailableTimesModal: false,
      selectedTherapist: null,
      drawerIsOpen: false,
      showProProfiles: false,
      recommendedProviders: [],
      showErrorModal: false,
      error: null,
    };
    this.errorModal = this.errorModal.bind(this);
    this.displayProviderInfo = this.displayProviderInfo.bind(this);
    this.getRelevantImg = this.getRelevantImg.bind(this);
    this.refreshProImage = this.refreshProImage.bind(this);
    this.addressModal = this.addressModal.bind(this);
    this.openChatWithPro = this.openChatWithPro.bind(this);
    this.createGroupChat = this.createGroupChat.bind(this);
    this.checkIfClientIsABTestAssigned = this.checkIfClientIsABTestAssigned.bind(this);
    this.abAssignmentOnLoad = this.abAssignmentOnLoad.bind(this);
    this.listOfRequestedProviders = this.listOfRequestedProviders.bind(this);
    this.prosListPopup = this.prosListPopup.bind(this);
    this.showRelevantStatus = this.showRelevantStatus.bind(this);
    this.showAltTimes = this.showAltTimes.bind(this);
    this.blindAuctionOption = this.blindAuctionOption.bind(this);
    this.prosProfilesPopup = this.prosProfilesPopup.bind(this);
    this.startTimeCheck = this.startTimeCheck.bind(this);
    this.updateBlindAuctionOption = this.updateBlindAuctionOption.bind(this);
    this.hasAltTimesSugg = this.hasAltTimesSugg.bind(this);
    this.gaCheck = this.gaCheck.bind(this);
    this.fetchRecommendedPros = this.fetchRecommendedPros.bind(this);
    this.allowRequestingMore = this.allowRequestingMore.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.refreshProImage(this.props), 2000);
    this.abAssignmentOnLoad();
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const hasAltTimesPro = find(requestedProviders, (elt) => (elt.offer_status === 'provided_alternate_times'));
    if (this.hasAltTimesSugg()) {
      this.showAltTimes(hasAltTimesPro);
    }
    this.fetchRecommendedPros();
  }

  componentWillReceiveProps(nextProps) {
    const oldCartId = get(this.props, 'appointment.id', '');
    const newCartId = get(nextProps, 'appointment.id', '');
    if (newCartId && oldCartId !== newCartId) {
      this.refreshProImage(nextProps);
      this.fetchRecommendedPros(nextProps);
    }
  }

  componentDidUpdate(prevProps) {
    const prevCartId = get(prevProps, 'appointment.id', '');
    const nextCartId = get(this.props, 'appointment.id', '');
    const isAddressModalOpen = get(this.props, 'openRelevantModal', false);
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const hasAltTimesPro = find(requestedProviders, (elt) => (elt.offer_status === 'provided_alternate_times'));
    console.log("alt", hasAltTimesPro)
    if (nextCartId && prevCartId !== nextCartId && ((isAddressModalOpen && this.state.abAssign !== 'new_flow') || (!isEmpty(hasAltTimesPro)))) {
      const pro = get(this.props, 'appointment.cartProducts.0.pro', null) || get(this.props, 'appointment.therapist_preferences.0', null);
      this.setState({ isAddressModalOpen, providerName: get(pro, 'first_name', ''), providerId: get(pro, 'id', '') }, () => {
        this.showAltTimes(hasAltTimesPro);
      });
    }
    if (get(this.props, 'prosPopupOpen', false) && !get(prevProps, 'prosPopupOpen', false)) {
      const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
      const proId = get(this.props, 'appointment.cartProducts.0.pro.id', '');
      if (size(requestedProviders) > 1) {
        this.setState({ showProsList: true });
      } else if (proId) {
        seProviderContact('appointment_details_page');
        this.openChatWithPro([proId]);
      }
    }
  }

  hasAltTimesSugg() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const hasAltTimesPro = find(requestedProviders, (elt) => (elt.offer_status === 'provided_alternate_times'));
    return !isEmpty(hasAltTimesPro);
  }

  startTimeCheck() {
    const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
    const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));

    return Boolean(bkngDate.diff(nowDate, 'minutes') >= 140);
  }

  gaCheck() {
    const cart = get(this.props, 'appointment', {});
    const marketplaceEnabled = get(cart, 'info_fields.marketplace_enabled', false);
    const isConfirmed = get(cart, 'info_fields.tracker.state', 'requested') === 'confirmed';
    const prefs = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const ther_prefs = get(this.props, 'appointment.therapist_preferences', []);
    return marketplaceEnabled && !isConfirmed && (!isEmpty(prefs) || !isEmpty(ther_prefs));
  }

  updateBlindAuctionOption(optn) {
    updateCart(get(this.props, 'appointment.id', null), { blind_auction_switch: optn }, '', (resp) => {
      this.props.setAppointment(decode(resp.data.cart));
    });
  }

  fetchRecommendedPros(potentialProps = null) {
    const specificProps = potentialProps ? potentialProps : this.props;
    const cart = get(specificProps, 'appointment', {});
    const marketplaceEnabled = get(cart, 'info_fields.marketplace_enabled', false);
    const rebook = get(specificProps, 'appointment.rebook', false);
    const isCouple = size(get(specificProps, 'appointment.cartProducts')) > 1;
    if (!rebook && marketplaceEnabled && !isCouple) {
      const cartId = get(cart, 'id', '');
      const cartProducts = get(cart, 'cartProducts', []);
      getRecommendedPros(cartId, get(cartProducts, '0.id', ''), (resp) => {
        const recommendedProviders = get(resp, 'data.therapists', []);
        this.setState({ recommendedProviders });
      }, (err) => {
        this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
      });
    }
  }

  allowRequestingMore() {
    const rebook = get(this.props, 'appointment.rebook', false);
    return !rebook && this.startTimeCheck() && this.gaCheck() && !isEmpty(this.state.recommendedProviders) && !get(this.props, 'appointment.request_pros_limit_reached', false);
  }

  blindAuctionOption() {
    // this is removed from design (not used currently)
    const cart = get(this.props, 'appointment', null);
    const bkngDate = moment(get(cart, 'time.utc', ''));
    const nowDate = moment().tz(get(cart, 'time.timezone', ''));
    const marketplaceEnabled = get(cart, 'info_fields.marketplace_enabled', false);
    const isConfirmed = get(cart, 'info_fields.tracker.state', 'requested') === 'confirmed';
    if (marketplaceEnabled && !isConfirmed) {
      <div className="row align-items-center p-16">
        <div className="col-xs-11 col-sm-10 size-14-20 contentSecondary txt-left">
          {dynamicCopyForDefaultBlindAuction(bkngDate.diff(nowDate, 'hours'))}
        </div>
        <div className="col-xs-1 col-sm-2 centered p-12">
          <input
            className="w-h-24"
            type="checkbox"
            checked={get(this.props, 'booking.cart.blind_auction_switch', false)}
            onChange={(e) => {
              this.updateBlindAuctionOption(e.target.checked);
            }}
          />
        </div>
      </div>;
    }
  }

  abAssignmentOnLoad() {
    const userId = get(this.props, 'client.user_id', '');
    if (!this.props.abTest) {
      getClientById(userId, (resp) => {
        const abAssignment = get(resp, 'data.client.ab_tests.web_booking_flow_test_v2');
        // new_flow , existing_flow
        this.props.setABTest(abAssignment);
        this.setState({ abAssign: this.props.abTest });
      });
    }
  }

  checkIfClientIsABTestAssigned(providerName, providerId) {
    // new_flow , existing_flow
    if (this.state.abAssign === 'new_flow') {
      createRebookCart(null, providerId, (resp_2) => {
        this.props.setCart({
          ...this.props.booking,
          ...{ cart: decode(resp_2.data.cart) },
        });
        this.setState({ redirect: true });
      });
    } else {
      this.setState({ isAddressModalOpen: true, providerName, providerId });
    }
  }

  refreshProImage(relevantProps) {
    const cartProducts = filter(get(relevantProps, 'appointment.cartProducts', []), (cp) => (!isEmpty(cp.pro)));
    if (!isEmpty(cartProducts)) {
      map(cartProducts, (cpObj) => {
        this.getRelevantImg(cpObj.pro);
      });
    }
  }

  addressModal() {
    const { isAddressModalOpen, providerId } = this.state;
    console.log("in parent", providerId)
    return (
      <AddressList
        providerId={providerId}
        isAddressModalOpen={isAddressModalOpen}
        hideModal={() => this.setState({ isAddressModalOpen: false })}
      />
    );
  }

  getRelevantImg(pro) {
    getProPrivateProfile(pro?.id, (response) => {
      this.setState({ [pro?.id]: get(response, 'data.therapist.private_avatar_url', '') });
    }, (err) => {
      console.log({ err });
      this.setState({ [pro?.id]: get(pro, 'avatar_url', '') });
    });
  }

  openChatWithPro(proIds) {
    openChat(compact(proIds), (resp) => {
      const crtPrds = get(resp, 'data.cart.display_helpers.v0.cart_products', []);
      this.props.fetchChat(get(resp, 'data', {}));
      seInboxViewMessage({
        state: getSingleChatState(get(resp, 'data.chats', [])),
        click_source: 'appointment_details',
        cart_product_count: size(crtPrds),
        booking_status: get(resp, 'data.cart.info_fields.tracker.state', 'pending'),
        is_rebook: get(resp, 'data.cart.info_fields.rebook', false),
        service_category: get(crtPrds, '0.product.title', ''),
        service_modality: get(crtPrds, '0.cart_product_main_option.title', ''),
      });
      this.setState({ showProsList: false });
      window.location.href = `${HOST_ROOT}${ROUTES.inbox}?chat_id=${get(this.props, 'currentChat.chat_id', '')}`;
    }, (err) => {
      // eslint-disable-next-line no-console
      console.log({ err });
      this.setState({ showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  showAltTimes(eachPro) {
    getAvailableTimesOfPro({ cartId: get(this.props, 'appointment.id', ''), cart_product_id: get(this.props, 'appointment.cartProducts.0.id', ''), therapist_id: eachPro?.id }, (response) => {
      const immediateCart = get(response, 'data', null);
      const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
      const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
      const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));
      const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
      if (immediateCart) {
        this.props.setRebookOptions(immediateCart);
        const hasAlternateTimes = !isEmpty(get(immediateCart, 'booking_options', []));
        this.setState({
          isOpenAvailableTimesModal: Boolean(hasAlternateTimes),
          selectedTherapist: eachPro,
        });
        if (hasAlternateTimes) {
          const click_source = get(this.props, 'upcomingAppointmentId', '') ? 'appointment_details' : 'email_link';
          seViewAlternativeTimes(click_source, get(immediateCart, 'cart.rebook', false), {
            ab_test_booking_flow: get(this.props, 'abTest'),
            market: get(this.props, 'appointment.address.market', ''),
            is_special_request: get(this.props, 'appointment.rebook', false),
            lead_time_selected: bkngDate.diff(checkoutDate, 'hours'),
            is_today: bkngDate.isSame(nowDate, 'day'),
            number_of_requested_pros: size(requestedProviders),
            number_of_pros_unavailable: size(filter(requestedProviders, (elt) => (elt.offer_status === 'rejected'))),
            number_of_pros_unresponsive: size(filter(requestedProviders, (elt) => (elt.offer_status === 'pending_response'))),
            time_til_appointment: bkngDate.diff(nowDate, 'hours'),
            blind_auction_opt_in: get(this.props, 'appointment.blind_auction_switch', false),
          });
        }
      }
      this.setState({ showProsList: false });
    }, (err) => {
      this.props.setRebookOptions({});
      this.setState({ isOpenAvailableTimesModal: false, selectedTherapist: null, showErrorModal: true, error: get(err, 'response.data.errors.0.message', defaultErrorMessage) });
    });
  }

  displayProviderInfo(cpObj) {
    const pro = get(cpObj, 'pro', null) || get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences.0', null) || get(this.props, 'appointment.therapist_preferences.0', null);
    const genericStatus = get(this.props, 'appointment.info_fields.tracker.state', 'requested');
    const currentStatus = get(this.props, 'appointment.tracking.status', 'searching');
    const cartProducts = get(this.props, 'appointment.cartProducts', []);
    const number_of_bookings = get(pro, 'number_of_bookings', 0);
    const isRebook = get(this.props, 'appointment.rebook', false);
    const is_event = get(this.props, 'appointment.is_event', false);
    if (pro) {
      return (
        <div className="br-btm-gray-4">
          <div className="p-24-16-0">
            <div className="size-24-32 color-black medium-font mb-8">
              About
              {' '}
              {get(cpObj, 'client_name', '')}
              's Provider
            </div>
            <div className="display-flex">
              <div className="flex-1">
                <div className="size-18-24 color-black medium-font mb-4">
                  {get(pro, 'first_name', '')}
                  {' '}
                  {get(pro, 'last_name.0', '')}
                  .
                </div>
                <div className="size-14-20 color-light-gray mb-12">
                  <Star style={{ color: '#F58266' }} />
                  {get(pro, 'rating', '')}
                  {' '}
                  {get(pro, 'rated', '') ? `(${pro.rated})` : ''}
                  {number_of_bookings ? ` (${number_of_bookings})` : ''}
                </div>
                <div className="size-14-20 color-gray mb-8">{get(pro, 'bio', '')}</div>
              </div>
              <div className="align-baseline mb-12">
                <ProProfilePopup therapist={pro} click_source={this.props.xsDisplay ? 'inbox_message' : 'appointment_details'} isPrivate>
                  <img className="provider-img" src={get(this.state, pro?.id, '') || get(pro, 'avatar_url', '') || placeholder} alt="" />
                </ProProfilePopup>
              </div>
            </div>
          </div>
          {size(cartProducts) === 1 && (genericStatus === 'complete' || currentStatus === 'arrived') && !is_event
            ? (
              <div
                className="clickable"
                id="rebookWithPro"
                onClick={() => {
                  this.checkIfClientIsABTestAssigned(
                    get(pro, 'first_name', ''),
                    get(pro, 'id', ''),
                  );
                }}
              >
                <TextWithIcon
                  muiIcon={<Replay />}
                  title={`Rebook with ${get(pro, 'first_name', '')}`}
                  displayArrow
                  displayBorder={this.allowRequestingMore()}
                />
              </div>
            )
            : (
              <>
                {/* if ui used in inbox */}
                {this.props.xsDisplay || (!isRebook && !(includes(['partially_confirmed', 'confirmed', 'on_the_way', 'complete'], genericStatus) && get(pro, 'id', ''))) ? null
                  : (
                    <div
                      className="clickable"
                      onClick={() => {
                        seProviderContact('appointment_details_page');
                        this.openChatWithPro([get(pro, 'id', '')]);
                      }}
                    >
                      <TextWithIcon
                        muiIcon={<Message />}
                        title={`Contact ${get(pro, 'first_name', '')}`}
                        displayArrow
                        displayBorder={this.allowRequestingMore()}
                      />
                    </div>
                  )}
              </>
            )}
          {this.allowRequestingMore() ? (
            <div
              className="clickable"
              onClick={() => {
                this.setState({ drawerIsOpen: true });
              }}
            >
              <TextWithIcon
                muiIcon={<GroupAdd />}
                title="Request more providers"
                displayArrow
              />
            </div>
          ) : null}
        </div>
      );
    }
  }

  createGroupChat(prds) {
    const genericStatus = get(this.props, 'appointment.info_fields.tracker.state', 'requested');
    const isRebook = get(this.props, 'appointment.rebook', false);
    if (size(prds) > 1 && !this.props.xsDisplay && (isRebook || includes(['confirmed', 'on_the_way', 'complete'], genericStatus))) {
      return (
        <div className="br-btm-gray-4">
          <div className="clickable" onClick={() => this.openChatWithPro(map(prds, (pr) => (get(pr, 'pro.id', ''))))}>
            <TextWithIcon
              muiIcon={<Group />}
              title="Group Chat"
              displayArrow
            />
          </div>
        </div>
      );
    }
  }

  showRelevantStatus(eachPro) {
    const proStatus = get(eachPro, 'offer_status', 'pending_response');
    switch (proStatus) {
      case 'rejected':
        return <div className="contentSecondary size-14-20">Not available</div>;
      case 'provided_alternate_times':
        return <div className="systemRed size-14-20">Suggested other times</div>;
      default:
        return <div className="contentSecondary size-14-20">Waiting for response</div>;
    }
  }

  prosListPopup() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const lastInd = size(requestedProviders) - 1;
    const receivedAltTimes = this.hasAltTimesSugg();
    return (
      <LatestModalDesign
        back={() => { this.setState({ showProsList: false }); }}
        title={receivedAltTimes ? 'Are you flexible?' : 'Message providers'}
        subtitle={receivedAltTimes ? 'One or more of your requested Providers have suggested a different time slot.' : ''}
        isOpen={this.state.showProsList}
        hideFooter
      >
        {map(requestedProviders, (elt, ind) => (
          <div
            key={`req-pro-${ind}`}
            className={`clickable ${ind === lastInd && !this.allowRequestingMore() ? '' : 'br-b-opaque'}`}
            onClick={() => {
              if (elt.offer_status === 'provided_alternate_times') {
                this.showAltTimes(elt);
              } else {
                seProviderContact('appointment_details_page');
                this.openChatWithPro([elt?.id]);
              }
            }}
          >
            <TextWithIcon
              cstmImg={<img src={elt.avatar_url || placeholder} alt={elt.first_name || ''} className="br-rd-50 w-h-56" />}
              iconClass="col-xs-3 col-sm-2"
              txtClass="col-xs-9 col-sm-10"
              title={(
                <div>
                  <div className="contentPrimary size-16-20 medium-font mb-4">
                    {elt.first_name || ''}
                    {' '}
                    {elt.last_name || ''}
                  </div>
                  {this.showRelevantStatus(elt)}
                </div>
              )}
              displayArrow
            />
          </div>
        ))}
        {this.allowRequestingMore() ? (
          <div
            className="clickable br-b-opaque"
            onClick={() => {
              this.setState({ drawerIsOpen: true });
            }}
          >
            <TextWithIcon
              cstmImg={<AddCircleOutline className="BluePrimary w-h-24" />}
              title={<div className="size-16-20 medium-font BluePrimary">Request additional Providers</div>}
              displayArrow
            />
          </div>
        ) : null}
      </LatestModalDesign>
    );
  }

  prosProfilesPopup() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const lastInd = size(requestedProviders) - 1;
    return (
      <LatestModalDesign
        isOpen={this.state.showProProfiles}
        hideFooter
        hideBackArrow
      >
        <div className="row">
          <div
            className="col-xs-3 clickable"
            onClick={() => {
              this.setState({ showProProfiles: false });
            }}
          >
            <ArrowBack className="w-h-20-imp" />
          </div>
          <div className="col-xs-6 contentPrimary size-16-20 medium-font centered">View provider profiles</div>
        </div>
        {map(requestedProviders, (elt, ind) => (
          <div key={`req-pro-${ind}`}>
            <div
              className={`clickable ${ind === lastInd ? '' : 'br-b-opaque'}`}
              onClick={() => {
                // TODO open profile instead
                // to be rechecked
                // if (elt.offer_status === "provided_alternate_times") {
                //     this.showAltTimes(elt);
                // } else {
                //     this.openChatWithPro([elt.id])
                // }

              }}
            >
              <TextWithIcon
                cstmImg={(
                  <ProProfilePopup therapist={elt} click_source={this.props.xsDisplay ? 'inbox_message' : 'appointment_details'} isPrivate>
                    <img src={elt.avatar_url || placeholder} alt={elt.first_name || ''} className="br-rd-50 w-h-56" />
                  </ProProfilePopup>
                )}
                iconClass="col-xs-3 col-sm-2"
                txtClass="col-xs-9 col-sm-10"
                title={(
                  <div>
                    <div className="contentPrimary size-16-20 medium-font mb-4">
                      {elt.first_name || ''}
                      {' '}
                      {elt.last_name || ''}
                    </div>
                    {this.showRelevantStatus(elt)}
                  </div>
                )}
                displayArrow
              />
            </div>

          </div>
        ))}
        {this.allowRequestingMore() ? (
          <div
            className="clickable br-b-opaque"
            onClick={() => {
              this.setState({ drawerIsOpen: true });
            }}
          >
            <TextWithIcon
              cstmImg={<AddCircleOutline className="BluePrimary w-h-24" />}
              title={<div className="size-16-20 medium-font BluePrimary">Request additional Providers</div>}
              displayArrow
            />
          </div>
        ) : null}
      </LatestModalDesign>
    );
  }

  listOfRequestedProviders() {
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    if (get(this.props, "fromCancel", false)) {
      return (<>
        <div
          className="clickable"
          id="viewRequestedProvidersCancel"
          onClick={() => {
            const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
            const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
            const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));

            this.setState({ showProsList: true });
            seRequestedProToasterView({
              ab_test_booking_flow: get(this.state, 'abAssign', 'new_flow'),
              market: get(this.props, 'appointment.address.market', ''),
              number_of_requested_pros: size(requestedProviders),
              number_of_pros_unavailable: size(filter(requestedProviders, (elt) => (elt.offer_status === 'rejected'))),
              number_of_pros_unresponsive: size(filter(requestedProviders, (elt) => (elt.offer_status === 'pending_response'))),
              number_of_pros_alt_times: size(filter(requestedProviders, (elt) => (elt.offer_status === 'provided_alternate_times'))),
              lead_time_selected: bkngDate.diff(checkoutDate, 'hours'),
              is_today: bkngDate.isSame(nowDate, 'day'),
              blind_auction_opt_in: get(this.props, 'appointment.blind_auction_switch', false),
            });
          }}
        >
          <TextWithIcon
            muiIcon={<Message />}
            title="Contact Providers"
            displayArrow
            displayBorder
            noPaddingForIcon
          />

        </div>
        {this.allowRequestingMore() ? (
          <div
            className="clickable"
            onClick={() => {
              this.setState({ drawerIsOpen: true });
            }}
          >
            <TextWithIcon
              muiIcon={<GroupAdd />}
              title="Request more providers"
              displayArrow
              displayBorder
              noPaddingForIcon
            />
          </div>
        ) : null}
      </>
      );
    }
    return (
      <div className="p-24-16 br-btm-gray-4">
        <div className="br-b-opaque">
          <div className="contentPrimary size-24-32 font-weight-bold mb-8">Your requested providers</div>
          <div className="p-16 d-flex-only justify-content-space-between align-items-center">
            <div className="contentSecondary size-14-20">
              You have requested an appointment with
              {' '}
              {size(requestedProviders)}
              {' '}
              providers. You'll be confirmed when they accept.
            </div>
            <div>
              <AvatarGroup max={3}>
                {map(requestedProviders, (pro, it) => (
                  <Avatar
                    key={`elt-pro-${it}`}
                    src={get(pro, 'avatar_url', placeholder)}
                    alt={it}
                  />
                ))}
              </AvatarGroup>
            </div>
          </div>
        </div>
        <div
          className="clickable"
          id="viewRequestedProviders"
          onClick={() => {
            const bkngDate = moment(get(this.props, 'appointment.time.utc', ''));
            const checkoutDate = moment(get(this.props, 'appointment.time.checkout_time', '')).tz(get(this.props, 'appointment.time.timezone', ''));
            const nowDate = moment().tz(get(this.props, 'appointment.time.timezone', ''));

            this.setState({ showProsList: true });
            seRequestedProToasterView({
              ab_test_booking_flow: get(this.state, 'abAssign', 'new_flow'),
              market: get(this.props, 'appointment.address.market', ''),
              number_of_requested_pros: size(requestedProviders),
              number_of_pros_unavailable: size(filter(requestedProviders, (elt) => (elt.offer_status === 'rejected'))),
              number_of_pros_unresponsive: size(filter(requestedProviders, (elt) => (elt.offer_status === 'pending_response'))),
              number_of_pros_alt_times: size(filter(requestedProviders, (elt) => (elt.offer_status === 'provided_alternate_times'))),
              lead_time_selected: bkngDate.diff(checkoutDate, 'hours'),
              is_today: bkngDate.isSame(nowDate, 'day'),
              blind_auction_opt_in: get(this.props, 'appointment.blind_auction_switch', false),
            });
          }}
        >
          <TextWithIcon
            muiIcon={<Message />}
            title="Contact Providers"
            displayArrow
            displayBorder={this.allowRequestingMore()}
          />

        </div>
        {this.allowRequestingMore() ? (
          <div
            className="clickable"
            onClick={() => {
              this.setState({ drawerIsOpen: true });
            }}
          >
            <TextWithIcon
              muiIcon={<GroupAdd />}
              title="Request more providers"
              displayArrow
            />
          </div>
        ) : null}
      </div>
    );
  }
  errorModal() {
    return (
      <ErrorModal
        isOpen={this.state.showErrorModal}
        close={() => { this.setState({ showErrorModal: false, error: null }); }}
      >
        <p>{this.state.error}</p>
      </ErrorModal>
    );
  }

  render() {
    const products = get(this.props, 'products', []);
    const booking = get(this.props, 'booking', {});
    const cartProducts = filter(get(this.props, 'appointment.cartProducts', []), (cp) => (!isEmpty(cp.pro) || !isEmpty(cp.pap_therapist_preferences) || !isEmpty(this.props, 'appointment.therapist_preferences', [])));
    const requestedProviders = get(this.props, 'appointment.cartProducts.0.pap_therapist_preferences', []);
    const moreThanOne = Boolean(size(requestedProviders) > 1);
    const { recommendedProviders } = this.state;
    const prefs = get(this.props, 'appointment.therapist_preferences', []);
    const fromCancel = get(this.props, "fromCancel", false);

    if (this.state.redirect) {
      return (
        <Redirect to={{
          pathname: '/booking/MENU',
          state: {
            booking,
            products,
            upcomingAppointmentId: get(this.props, 'appointment.id', ''),
          },
        }}
        />
      );
    }
    if (isEmpty(cartProducts) && isEmpty(requestedProviders)) {
      return null;
    }
    if (fromCancel && !(!isEmpty(requestedProviders) && moreThanOne)) {
      return null;
      // todo revisit logic
    }
    if (!isEmpty(requestedProviders) && moreThanOne) {
      return (
        <>
          {this.listOfRequestedProviders()}
          {this.prosListPopup()}
          {/* {this.prosProfilesPopup()} */}
          <AvailableTimesModal
            isOpenAvailableTimesModal={this.state.isOpenAvailableTimesModal}
            hideModalFromParent={() => this.setState({
              isOpenAvailableTimesModal: false,
              selectedTherapist: null,
            })}
            selectedTherapist={this.state.selectedTherapist}
          />
          {this.state.drawerIsOpen && this.allowRequestingMore() ? (
            <ProListDrawer
              click_source="Appointment_Details"
              isOpen={this.state.drawerIsOpen && this.allowRequestingMore()}
              closeAndNext={() => {
                this.setState({ drawerIsOpen: false });
              }}
              assignToProps={({ cart }) => this.props.setAppointment(cart)}
              recommendedProviders={recommendedProviders}
              cartId={get(this.props, 'appointment.id', '')}
              cartProductId={get(this.props, 'appointment.cartProducts.0.id', '')}
            />
          ) : null}
          {this.errorModal()}
        </>
      );
    }
    if (isEmpty(requestedProviders) && !isEmpty(prefs) && size(prefs) === 1) {
      return (
        <>
          {this.createGroupChat(cartProducts)}
          {this.displayProviderInfo(assign(get(cartProducts, '0', {}), { pro: prefs[0] }))}
          {this.addressModal()}
          {this.state.drawerIsOpen && this.allowRequestingMore() ? (
            <ProListDrawer
              click_source="Appointment_Details"
              isOpen={this.state.drawerIsOpen && this.allowRequestingMore()}
              closeAndNext={() => {
                this.setState({ drawerIsOpen: false });
              }}
              assignToProps={({ cart }) => this.props.setAppointment(cart)}
              recommendedProviders={recommendedProviders}
              cartId={get(this.props, 'appointment.id', '')}
              cartProductId={get(this.props, 'appointment.cartProducts.0.id', '')}
            />
          ) : null}
          {this.errorModal()}
        </>
      );
    }
    return (
      <>
        {this.createGroupChat(cartProducts)}
        {map(cartProducts, (cpObj) => (
          <div key={`pro-cp-${cpObj?.id}`}>
            {this.displayProviderInfo(cpObj)}
          </div>
        ))}
        {this.addressModal()}
        {this.state.drawerIsOpen && this.allowRequestingMore() ? (
          <ProListDrawer
            click_source="Appointment_Details"
            isOpen={this.state.drawerIsOpen && this.allowRequestingMore()}
            closeAndNext={() => {
              this.setState({ drawerIsOpen: false });
            }}
            assignToProps={({ cart }) => this.props.setAppointment(cart)}
            recommendedProviders={recommendedProviders}
            cartId={get(this.props, 'appointment.id', '')}
            cartProductId={get(this.props, 'appointment.cartProducts.0.id', '')}
          />
        ) : null}
        {this.errorModal()}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  client: state.client,
  appointment: state.appointment,
  chats: state.chats,
  currentChat: state.currentChat,
  abTest: state.abTest,
  upcomingAppointmentId: state.upcomingAppointmentId,
  rebookOptions: state.rebookOptions,
});
export default connect(mapStateToProps, {
  setCart, fetchChat, setABTest, setRebookOptions, setAppointment,
})(ProviderOfAppt);
